import React, { createContext, useState } from 'react';
import { notifications_logic, notifications_style } from '../../styles';
// Create the NotificationContext
export const NotificationContext = createContext(null);

/**
 * NotificationController component manages the notifications and provides a way to send new notifications.
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 */
export function NotificationController({ children }) {
  const [notifications, setNotifications] = useState([]);

  /**
   * Sends a new notification.
   * @param {Object} settings - The notification settings.
   * @param {string} settings.type - The type of the notification.
   * @param {string} settings.message - The message of the notification.
   * @param {number} [settings.duration=3000] - The duration of the notification in milliseconds.
   * @throws {Error} If the notification type is not set.
   */
  function sendMessage(settings) {
    const { type, message, duration = 3000 } = settings;
    if (!type) throw new Error(`Notification Type not set for notification '${message}'`);

    const id = Date.now(); // Unique ID for each notification
    const newNotification = { id, type, message, duration };

    setNotifications(prevNotifications => [...prevNotifications, newNotification]);

    // Remove the notification after the specified duration
    setTimeout(() => {
      // Find the element by its ID and add the 'fade-out' class
      const element = document.getElementById(id);
      if (element) {
        element.classList.add(notifications_logic.fade_out);
      }
    
      // Wait 0.45 seconds before removing it from the DOM
      setTimeout(() => {
        setNotifications(prevNotifications => 
          prevNotifications.filter(notification => notification.id !== id)
        );
      }, 450);
    }, duration);
  }

  const context = {
    sendMessage
  };

  return (
    <NotificationContext.Provider value={context}>
      {children}
      <div className={notifications_logic.notification_container}>
        {notifications.map(notification => (
          <div 
            key={notification.id} 
            id={notification.id} 
            className={[notifications_logic.notification, notifications_style[notification.type], notifications_style.notification].join(' ')}
            onClick={() => {
              setNotifications(prevNotifications => 
                prevNotifications.filter(n => n.id !== notification.id)
              );
            }}
          >
            {notification.message}
          </div>
        ))}
      </div>
    </NotificationContext.Provider>
  );
}