import { useEffect, useState, useContext } from "react"
import { Header, PagerContext, ResultList, Popup, Button, Delete } from "../../../components";
import { DataStorageContext } from "../../../logic";
import { ClockIcon, StarIcon, XmarkIcon } from "../../../Images";

import { beloven_logic, beloven_style } from "../../../styles/";
export default function Feedback() {
    const pager = useContext(PagerContext);
    const { readData } = useContext(DataStorageContext);
    const [feedback, setFeedback] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [activeFeedback, setActiveFeedback] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            pager.allowed_on_page()
            setFeedback(await readData('feedback'));
        };
        fetchData();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Header title="Feedback" backUrl='/beheer/applicatie' playAnimation={false}/>

            {feedback.success && <ResultList data={feedback.values} limit={25} title={(feedback) => 
            <div onClick={() => {setActiveFeedback(feedback); setShowPopup(true)}}>
                <div style={{width: '100%', margin: '0', textWrap: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{feedback.feedback}</div>
                <div style={{display: "flex"}}>
                    <p style={{margin: '0', marginLeft: '10px'}}>{feedback.pagina}</p>
                    <StarIcon style={{width: '18px', fill: 'white', marginLeft: '10px'}}/>
                    <p style={{margin: '0', marginLeft: '10px'}}>{feedback.rating || "Geen rating"}</p>
                </div>
            </div>
            }/>}

            {showPopup && 
                <div className={beloven_logic.popupContainer}>
                <Popup toggle={setShowPopup} style={{width: '80%', height: '80%', backgroundColor: 'rgba(29, 29, 31, 0.9)', border: 'none'}}>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', gap: '15px', height: '100%'}}>
                        <div style={{width: '100%', height: 'calc(100% - 100px)', }}>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '15px'}}>
                            <p style={{width: '80%', overflow: 'hidden', textWrap: 'nowrap', textOverflow: 'ellipsis', margin: '0', color: "white", fontWeight: '700', fontSize: '.9rem', textAlign: 'center', marginLeft: '15px'}}>
                                Feedback van <span style={{color: '#6060f1', borderBottom: '1px solid #6060f1', cursor: 'pointer'}} onClick={() => window.location.href = `mailto:${activeFeedback.email}`}>{activeFeedback.naam}</span> - {activeFeedback.locatieNaam}
                            </p>                                
                            <button className={[beloven_logic.closeButton, beloven_style.closeButton].join(' ')} onClick={() => setShowPopup(false)}><XmarkIcon/></button>
                            </div>
                            <p style={{margin: '2px 0', color: "white", fontSize: '.8rem', height: '100%', marginLeft: '15px', overflow: 'scroll', wordWrap: 'break-word', whiteSpace: 'pre-wrap'}}>
                                {activeFeedback.feedback}
                            </p>                        
                        </div>

                        <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{display: 'flex'}}>
                                <StarIcon style={{width: '18px', fill: 'white', marginLeft: '10px'}}/>
                                <p style={{margin: '0', fontSize: '.8rem', marginLeft: '10px', color: 'white'}}>{activeFeedback.rating || "Geen rating"}</p>
                            </div>
                            <div style={{display: 'flex'}}>
                                <ClockIcon style={{width: '18px', fill: 'white', marginLeft: '10px'}}/>
                                <p style={{margin: '0', fontSize: '.8rem', marginLeft: '10px', color: 'white'}}>{formatDate(activeFeedback.datum)}</p>
                            </div>
                            <Delete title="Weet je zeker dat je deze feedback wilt verwijderen?" storageName='feedback' callback={() => setShowPopup(false)} endpoint="/api/data/feedback" id={activeFeedback.feedbackID}/>
                        </div>
                    </div>
                </Popup>
            </div>}

        </>
    )
}

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear().toString().substr(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };