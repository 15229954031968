import { useState, useContext, useEffect } from 'react'

import { PagerContext, Button, Input, NotificationContext, Password} from "../components";
import { Config } from '../pageConfig';

import { postRequest, DataStorageContext } from '../logic';
import { login_logic, login_style } from '../styles';
import { KwaliteitLogo } from '../Images/index'
export default function Login() {
  const pager = useContext(PagerContext);
  const dataStorage = useContext(DataStorageContext);
  const notificationController = useContext(NotificationContext);
  const [locatieNummer, setLocatieNummer] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [location, setLocation] = useState("");

  const login = async (e) => {
    e.preventDefault()
    if (!username || !password) return notificationController.sendMessage({type: 'warning', message: 'Geef een gebruikersnaam en wachtwoord op.'})

    let data;
    await postRequest('/api/auth/login', {
      headers: {'Authorization': `Basic ${btoa(`${username}|${password}`)}`, 'Content-Type': 'application/json'},
      body: JSON.stringify({locatieNummer: locatieNummer, location: location})
    }).then((response) => {data = response})
    setUsername("")
    setPassword("")
    console.log(data)
    console.log(!data.success && data.message === "INVALID_CREDENTIALS")
    if (!data.success && data.message === "INVALID_CREDENTIALS") return notificationController.sendMessage({type: 'error', message: 'Ongeldige aanmeld gegevens.'})
    if (!data.success && data.message === "INVALID_AUTHORIZATION") return notificationController.sendMessage({type: 'error', message: 'Ongeldige aanmeld gegevens.'})
    if (!data.success && data.message === "ACCOUNT_DISABLED") return notificationController.sendMessage({type: 'error', message: 'Uw account is gedeactiveerd.'})
    if (!data.success && data.message === "ACCOUNT_LOCKED") return notificationController.sendMessage({type: 'error', message: 'Uw account is geblokkeerd\nNeem contact op met uw beheerder.'})
    if (!data.success && data.message === "ACCOUNT_RESET_PASSWORD") return notificationController.sendMessage({type: 'error', message: 'U bent verplicht om uw wachtwoord te wijzigen.\nMocht u geen mail ontvangen hebben, neem contact op met uw beheerder.'})
    if (!data.success && data.message === "GEO_PROMPT_DENIED") return notificationController.sendMessage({type: 'error', message: 'Uw locatie is niet beschikbaar.\nSta toegang tot uw locatie toe.'})
    if (!data.success && data.message === "GEO_NOT_SUPPORTED") return notificationController.sendMessage({type: 'error', message: 'Uw apparaat word niet ondersteund.\nNeem contact op met uw beheerder.'})
    if (!data.success && data.message === "NO_LOCATION_CODE_PROVIDED") return notificationController.sendMessage({type: 'error', message: 'Geef een locatie code op.'})
    if (!data.success && (data.message === 'UNKNOWN' || data.message === '')) return notificationController.sendMessage({type: 'error', message: 'Een onbekende fout is opgetreden.'})
    if (data.success) dataStorage.saveData('permission', {rechtengroep: data.rechtengroep})
    if (data.success) dataStorage.saveData('animation', {name: 'page_transition', callback: () => pager.navigateTo(Config.Dashboard.url)})
  }

  useEffect(() => {
    const startLocation = async () => setLocation(await getLocation())
    startLocation()
    // eslint-disable-next-line 
  }, [])

  return (
      <div className={[login_logic.panel, login_style.panel].join(' ')}>
        <div className={login_logic.logoContainer}>
          <img src={KwaliteitLogo} className={login_logic.logo} alt=""></img>
        </div>
        <form className={login_logic.inputForm}>
          <Input  title="Locatie Nummer" placeholder="Vul dit in als u bij meerdere locaties werkt" value={locatieNummer} setValue={setLocatieNummer} tabIndex={1}/>
          <Input  title="Gebruikersnaam" placeholder="Gebruikersnaam" value={username} setValue={setUsername} tabIndex={2}/>
          <Password title="Wachtwoord" placeholder="Wachtwoord" value={password} setValue={setPassword} tabIndex={3}/>
          <Button title='Aanmelden' type="primary" callback={(e) => login(e)} styles={{width: '80%', display: 'block', margin: 'auto'}} tabIndex={4}></Button>
        </form>
        <p className={[login_logic.privacy, login_style.privacy].join(' ')}>Bij aanmelding gaat u akkoord met onze <span onClick={() => pager.navigateTo('/privacy')} className={login_style.link}>privacyvoorwaarden</span>.</p>
      </div>
  );
}

function getLocation() {
  return new Promise((resolve) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          resolve({ success: true, values: { latitude, longitude } });
        },
        (error) => {
          if (error.code === 1) {
            resolve({ success: false, values: {latitude: null, longitude: null}, message: "PROMPT_DENIED" });
          } else {
            resolve({ success: false, values: {latitude: null, longitude: null}, message: "UNKNOWN" });
          }
        }
      );
    } else {
      resolve({ success: false, values: {latitude: null, longitude: null}, message: "GEO_NOT_SUPPORTED" });
    }
  });
}