import { useContext, useEffect, useState } from "react";
import { PagerContext, Header, Steps, Input, Dropdown, Button, Search, Checkbox, NotificationContext, Delete, ResultList, MenuButton, ListButton} from "../../../components";
import { DataStorageContext, putRequest, fetchMedia } from "../../../logic";
import { beheer_logic, beheer_style } from '../../../styles'
import { CheckIcon } from "../../../Images";

const rechtengroepen = {
  1: 'Applicatie beheerder',
  2: 'Administratief beheerder',
  3: 'Locatie beheerder',
  4: 'Medewerker',
  5: 'Controle',
  6: 'Naasten',
  7: 'Demo'
}

const statusen = {
  1: 'Actief',
  2: 'Innactief',
  3: 'Geblokkeerd',
  4: 'Wachtwoord Reset'
}

// ! Document code

export default function AccountEditor() {
  const pager = useContext(PagerContext)
  const { readData, clearData, saveData } = useContext(DataStorageContext)
  const notificationController = useContext(NotificationContext)

  const [requestSend, setRequestSend] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [data, setData] = useState()

  const [bewoners, setBewoners] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [permission, setPermission] = useState()

  const [limit, setLimit] = useState(8)

  let loadMore = false;

  useEffect(() => {
    const fetchData = async () => {
      await pager.allowed_on_page()

      setPermission((await readData('permission')).values.rechtengroep)

      let _bewoners = await readData('bewoners')
      setBewoners(_bewoners)
      createDataObject(_bewoners)
      fetchBewoners(_bewoners)
    };
    fetchData();
    // eslint-disable-next-line
  }, [pager.arguments]);

  useEffect(() => {
    fetchBewoners()
    setLimit(8)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  const fetchBewoners = async (bewonerList) => {
    let index = 0;
    bewonerList = bewonerList ? bewonerList : bewoners
    
    if (!bewonerList.success) return;
    const _bewoners = await Promise.all(bewonerList.values.map(async (bewoner) => {
      if (index >= 10) return bewoner
      if (searchValue && !bewoner.naam.toLowerCase().includes(searchValue.toLowerCase())) return bewoner;
      if (typeof bewoner.profielfoto === 'string' && !bewoner.profielfoto.startsWith('blob')) {
        index = index + 1
        const data = await fetchMedia(`/api/data/media?${new URLSearchParams({id: bewoner.profielfoto})}`, {})
        if (data.status === 500) { bewoner.profielfoto = null; return bewoner}
        const blob = await data.blob()
        bewoner.profielfoto = URL.createObjectURL(blob)
      }
      return bewoner
    }))

    setBewoners({ success: true, values: _bewoners });
    await saveData('bewoners', _bewoners );

    return true;
  }

  const createDataObject = async (bewonerList) => {
    bewonerList = bewonerList ? bewonerList : bewoners

    const _values = Object.values((await readData('beheer_accounts')).values).find((item) => String(item.accountID) === pager.getArgument('account')) || {};
    if (Object.keys(_values).length === 0) return pager.navigateTo(`/beheer/locatie/accounts?locatie=${pager.getArgument('locatie')}`);
    let _data = Object.keys(_values).reduce((acc, key) => {
      acc[key] = _values[key];
      return acc;
    }, {});

    await clearData('beheer_gekopeldeBewoners') // * We clear the gekoppelde bewoners, because these are they are retrived per account.
    const gekoppelddeAccounts = await readData('beheer_gekopeldeBewoners');

    _data.gekoppeldeBewoners = [];

    for (const gekoppeld of gekoppelddeAccounts.values) {


      const bewoner = bewonerList.values.find(bewoner => bewoner.bewonerID === gekoppeld.bewonerID);
      if (!bewoner) continue;
      if (bewoner.profielfoto.startsWith('blob:')) {_data.gekoppeldeBewoners.push(bewoner); continue;}

      const response = await fetchMedia(`/api/data/media?${new URLSearchParams({id: bewoner.profielfoto})}`, {});
      if (response.status === 500) {
        bewoner.profielfoto = null;
        _data.gekoppeldeBewoners.push(bewoner);
        continue;
      }
      if (typeof response.blob === 'function') {
        const blob = await response.blob();
        bewoner.profielfoto = URL.createObjectURL(blob);
      } else bewoner.profielfoto = null;


      _data.gekoppeldeBewoners.push(bewoner);
    }

    setData(_data);
  };

  const save = async () => {
    setRequestSend(true);
    let result;
    const checkResult = checkInput(data)
    if (!checkResult.success) {setRequestSend(false); return notificationController.sendMessage({type: 'error', message: checkResult.message})}

    await putRequest('/api/data/account', {
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        accountID: data.accountID,
        gebruikersnaam: data.gebruikersnaam,
        email: data.email,
        naam: data.naam,
        rechtengroep: data.rechtengroep,
        status: parseInt(data.status),
        locatieVerplicht: data.locatieVerplicht,
        ...(data.rechtengroep === 6 && {koppelBewoners: data.gekoppeldeBewoners})
      })
    }).then((response) => {result = response})
    if (!result.success) {setRequestSend(false); return notificationController.sendMessage({type: 'error', message: 'Er is iets fout gegaan tijdens het opslaan van het account. Probeer het opnieuw.'})}
    clearData('beheer_accounts')
    readData('beheer_accounts')
    notificationController.sendMessage({type: 'success', message: `Het account voor ${data.gebruikersnaam} is succesvol opgeslagen.`})
    setEditMode(false)
  }

  useEffect(() => {console.log(data?.locatieVerplicht)}, [data])
	return (
    <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%'}}>

      <div>
        <Header 
          title={`Account: ${data?.naam || ''}`}
          backUrl={`/beheer/locatie/accounts?locatie=${pager.getArgument('locatie')}`}
          playAnimation={false}
        />

        {!editMode && <div>{generateOverview(data)}</div>}
      </div>

      {!editMode && <div style={{display: 'flex', justifyContent: 'space-between', paddingBottom: '10px'}}>
        <Button type="primary" title="Bewerken" callback={() => setEditMode(true)} styles={{width: '48%'}}/>
        <Delete title={`Account: ${data?.naam}`} endpoint="/api/data/organisatie" id={data?.accountID} storageName="beheer_organisaties" buttonStyles={{width: '48%'}} callback={() => pager.navigateTo(`/beheer/locatie/accounts?locatie=${pager.getArgument('locatie')}`)}/>
      </div>}

      {editMode && <Steps
        steps={[
          {
            title: "Wat is de naam van de accountgebruiker?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <Input required title="Naam" name="naam" value={data?.naam} setValue={setData} placeholder="Naam van de gebruiker"/>
                <Button type="primary" title="Bewerken Annuleren" callback={() => {setEditMode(false); createDataObject()}} styles={{width: '100%'}}/>
              </div>
            )
          },
          {
            title: "Wat is het mail-adres van de accountgebruiker?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <Input required title="E-mail" name="email" value={data?.email} setValue={setData} placeholder="E-mail van de gebruiker"/>
                <Button type="primary" title="Bewerken Annuleren" callback={() => {setEditMode(false); createDataObject()}} styles={{width: '100%'}}/>
              </div>
            )
          },
          {
            title: "Welke rechten heeft de accountgebruiker?",
            content: (handleSwipe) => (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <div>
                  {Object.keys(rechtengroepen).map((item) => {
                    if ([1, 5, 7].includes(parseInt(item))) return null
                    if (permission > item) return null // * Removes all permission groups with higher permission level.
                    return (
                      <div key={item}>
                        <ListButton title={rechtengroepen[item]} styles={data.rechtengroep === parseInt(item) ? {backgroundColor: 'var(--secondary-accent-color)'} : ''} callback={() => {
                          setData(prev => ({
                            ...prev,
                            rechtengroep: parseInt(item)
                          }))
                          handleSwipe('left')
                        }}/>
                      </div>
                    )
                  })}
                </div>
                <Button type="primary" title="Bewerken Annuleren" callback={() => {setEditMode(false); createDataObject()}} styles={{width: '100%'}}/>
              </div>
            )
          },
          data.rechtengroep === 6 && {
            title: "Koppel bewoners aan het account.",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <Search focus value={searchValue} setValue={setSearchValue} placeholder="Bewoner"/>
                <div style={{height: '80%', overflow: 'scroll'}}>{bewoners.success ? 
                    (() => {
                      const {bewonerList, _loadMore} = generateBewonersList(bewoners, searchValue, data, setData, setSearchValue, limit)
                      loadMore = _loadMore
                      return bewonerList
                  })() : null}
                </div>
                {loadMore && <Button styles={{width: '100%'}} type="primary" title="Laad meer" callback={async () => {await fetchBewoners(); setLimit(limit + 10)}}/>}
                <Button type="primary" title="Bewerken Annuleren" callback={() => {setEditMode(false); createDataObject()}} styles={{width: '100%'}}/>
              </div>
            )
          },
          {
            title: "Wat is de status van het account?",
            content: (handleSwipe) => (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <div>
                  {Object.keys(statusen).map((item) => {
                    return (
                      <div key={item}>
                        <ListButton title={statusen[item]} styles={data.status === parseInt(item) ? {backgroundColor: 'var(--secondary-accent-color)'} : ''} callback={() => {
                          setData(prev => ({
                            ...prev,
                            status: parseInt(item)
                          }))
                          handleSwipe('left')
                        }}/>
                      </div>
                    )
                  })}
                </div>
                <Button type="primary" title="Bewerken Annuleren" callback={() => {setEditMode(false); createDataObject()}} styles={{width: '100%'}}/>
              </div>
            )
          },
          data.rechtengroep === 4 && {
            title: "Moet de gebruiker verplicht op locatie aanwezig zijn?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <Checkbox title="Locatie verplicht" name="locatieVerplicht" value={data?.locatieVerplicht} setValue={setData} placeholder="Als dit vinkje aan staat moet de gebruiker altijd op de locatie aanwezig zijn om rapportages te mogen maken en bekijken."/>
                <Button type="primary" title="Bewerken Annuleren" callback={() => {setEditMode(false); createDataObject()}} styles={{width: '100%'}}/>
              </div>
            )
          },
          {
            title: "Kloppen deze gegevens?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <div>{generateOverview(data)}</div>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Button type="primary" title="Nee, annuleren" callback={() => setEditMode(false)} styles={{width: '45%'}}/>
                  <Button disabled={requestSend} type="primary" title="Ja, opslaan" callback={() => save()} styles={{width: '45%'}}/>
                </div>
              </div>
            )
          },
        ].filter(Boolean)}
      />
      }
		</div>
	);
}

function generateOverview(_data) {

  let data = {..._data}

  if (!data) return null
  const filter = ["accountID", "locatieID", "isActief"]
  if (data.rechtengroep !== 4) filter.push('locatieVerplicht')

  return Object.keys(data).map(item => {
    if (filter.includes(item)) return null

    if (item === 'locatieVerplicht') data[item] = data[item] === true ? "Ja" : "Nee"
    if (item === 'rechtengroep'    ) data[item] = rechtengroepen[data[item]] || data[item]
    if (item === 'status'          ) data[item] = statusen[data[item]] || data[item]

    if (item === 'gekoppeldeBewoners') {
      if (_data.rechtengroep !== 6) return null
      const bewoners = data[item]
    
      return (
        <div key={item}>
          <p className={beheer_logic.recordText} style={{color: 'var(--primary-text-color)'}}>Gekoppelde Bewoners</p>
          {bewoners.map((bewoner) => {
            return (
              <div key={bewoner.bewonerID} className={beheer_logic.recordContainer} style={{width: '100%', display: 'flex', justifyContent: 'start', gap: '15px', alignItems: 'center', color: 'var(--primary-text-color)'}}>
                {bewoner.profielfoto && <img src={bewoner.profielfoto} style={{borderRadius: '50%', width: '50px', height: '50px', aspectRatio: '1/1', objectFit: 'cover'}} alt=""></img>}
                <p>{bewoner.naam}</p>
              </div>
            )
          })}
        </div>
      )
    }

    return (
      <div className={beheer_logic.recordContainer} key={item} style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'var(--primary-text-color)'}}>
        <p className={beheer_logic.recordText}>{item}</p>
        <p className={beheer_logic.recordText} style={{textTransform: 'unset'}}>{data[item]}</p>
      </div>
    )
  })
}

function generateBewonersList(bewoners, searchValue, data, setData, setSearchValue, limit) {
  let _loadMore = false
  let index = 0
  if (!bewoners.success) return null;
  let bewonerList = bewoners.values.map((bewoner) => {
    if (index >= limit) {_loadMore = true; return null};
    const naam = bewoner.naam.toLowerCase();
    const lowerCaseSearchValue = searchValue.toLowerCase();
    if (!naam.includes(lowerCaseSearchValue)) return null;
    index++;
    return (
      <div key={bewoner.bewonerID} style={{userSelect: 'none', display: "flex", alignItems: 'center', padding: '5px'}} onClick={() => {
        if (data.gekoppeldeBewoners.find(item => item.bewonerID === bewoner.bewonerID)) {
          setData(prev => ({
            ...prev,
            gekoppeldeBewoners: prev.gekoppeldeBewoners.filter(item => item.bewonerID !== bewoner.bewonerID)
          }))
          return;
        }
        setData(prev => ({
          ...prev,
          gekoppeldeBewoners: [
            ...prev.gekoppeldeBewoners,
            bewoner
          ]
        }));
        setSearchValue('')
      }}>
        {data.gekoppeldeBewoners.some(item => item.bewonerID === bewoner.bewonerID) 
        ? <CheckIcon src={bewoner.profielfoto} style={{fill: 'white', backgroundColor: 'var(--notification-success)', borderRadius: '50%', width: '30px', height: '30px', padding: '10px', aspectRatio: '1/1', objectFit: 'cover'}}/>
        : <img src={bewoner.profielfoto} style={{borderRadius: '50%', width: '50px', height: '50px', aspectRatio: '1/1', objectFit: 'cover'}} alt=""></img>
        }
        <p style={{color: 'var(--primary-text-color)', fontSize: '1.2rem', margin: '0 0 0 10px', textWrap: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{bewoner.naam}</p>
      </div>
    )
  })

  return {bewonerList, _loadMore}
}

function checkInput(data) {
  if (!data?.naam) return { success: false, message: 'Voer een naam in.'}
  if (!data?.email) return { success: false, message: 'Voer een emailadres in.'}
  if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(data?.email)) return { success: false, message: 'Voer een geldig emailadres in. (voorbeeld@gmail.com)'}
  if (!data?.rechtengroep) return { success: false, message: 'Selecteer een rechtengroep.'}
  if (!data?.status) return { success: false, message: 'Selecteer een status.'}
  return {success: true}
}