import { button_logic, button_style } from '../../../styles'

/**
 * Button component.
 * @param {string} props.title - The title text for the button.
 * @param {string} props.type - The type of the button ('primary' or 'secondary').
 * @param {function} props.callback - The callback function to be called on button click.
 * @param {string} props.className - The class name for the button.
 * @param {Object} props.styles - The styles object for the button.
 * @param {boolean} props.disabled - Whether the button is disabled or not.
 * @param {number} props.tabIndex - The tab index for the button.
 * @returns {JSX.Element} The rendered button component.
 */
export default function Button({ className, styles, type, disabled, tabIndex, title, callback }) {
    let element_styles = {};
    if (styles || typeof styles === "object") element_styles = styles

    const classes = [
      Array.isArray(className) ? className.join(' ') : className,
      button_logic.button,
      disabled ? button_logic.disabled : '',
      type === 'primary' ? button_style.primaryButton : button_style.secondaryButton
    ].join(' ');

    return (
        <button 
          disabled={disabled} 
          tabIndex={tabIndex} 
          className={classes} 
          onClick={!disabled ? callback : undefined}
          style={element_styles}
        >
          {title}
        </button>
    )
}