// Todo: Add a date to the value from when it has been saved. If 5 minutes have passed do a new apiRequest.

import React, { createContext, useState } from "react";
import { assertUnreachable} from '../components'
import { fetchRequest } from '.'
export const DataStorageContext = createContext(null);
const version = '0.9.1'
function dataKeys() {
  return {
    notification: {},
    animation: {},
    permission: {
      api_url: '/api/data/permissions',
      params: {},
    },
    beloftes: {
      api_url: '/api/data/beloftes',
      params: {},
    },
    bewoners: {
      api_url: '/api/data/bewoners',	
      params: {},
    },
    bekijken: {
      api_url: '/api/data/bekijken',
      params: {},
    },
    logs: {
      api_url: '/api/data/logs',
      params: {},
    },
    feedback: {
      api_url: '/api/data/feedback',
      params: {},
    },
    beheer_locaties: {
      api_url: '/api/data/beheer/locaties',
      params: {}
    },
    beheer_organisaties: {
      api_url: '/api/data/beheer/organisaties',
      params: {}
    },
    beheer_accounts: {
      api_url: `/api/data/beheer/accounts?locatie=${new URLSearchParams(window.location.hash.split('?')[1]).get('locatie')}`,
      params: {}
    },
    beheer_bewoners: {
      api_url: `/api/data/beheer/bewoners?locatie=${new URLSearchParams(window.location.hash.split('?')[1]).get('locatie')}`,
      params: {}
    },
    beheer_gekopeldeBewoners: {
      api_url: `/api/data/beheer/gekopeldeBewoners?account=${new URLSearchParams(window.location.hash.split('?')[1]).get('account')}`,
      params: {}
    },
    beheer_beloftes: {
      api_url: `/api/data/beheer/beloftes?locatie=${new URLSearchParams(window.location.hash.split('?')[1]).get('locatie')}`,
      params: {}
    }
  }
}

export function DataStorage({ children }) {
  const [dataStorage, setDataStorage] = useState({});

  const saveData = (key, value) => {
    if (!dataKeys().hasOwnProperty(key)) assertUnreachable(`key '${key}' was not found in dataKeys object while saving data. /src/logic/dataStorage.js`);
    setDataStorage((prevData) => ({ ...prevData, [key]: value }));
  };

  const readData = async (key) => {
    let data;

    if (!dataKeys().hasOwnProperty(key)) assertUnreachable(`key '${key}' was not found in dataKeys object while reading data. /src/logic/dataStorage.js`);
    data = dataStorage[key];
    if (data) return {success: true, values: data};
    if (dataKeys()[key].api_url === undefined) return {success: false, value: ''};
    
    data = await fetchRequest(dataKeys()[key].api_url, dataKeys()[key].params);
    if (!data.success) return { success: false, message: 'Failed to retrive data from api.'}
    if (data.version !== version) return window.location.href = '/';
    saveData(key, data.values) 
    return {success: true, values: data.values};
  };

  const clearData = (key) => {
    if (!dataKeys().hasOwnProperty(key)) assertUnreachable(`key '${key}' was not found in dataKeys object while clearing data. /src/logic/dataStorage.js`);
    setDataStorage((prevData) => {
      delete prevData[key];
      return prevData;
    });
  }


  const clearAll = () => {
    setDataStorage({});
  };

  const context = {
    data: dataStorage,
    saveData: saveData,
    readData: readData,
    clearData: clearData,
    clearAll: clearAll,
  }

  return (
    <DataStorageContext.Provider value={context}>
      {children}
    </DataStorageContext.Provider>
  );
}
