import { beheer_logic, beheer_style } from "../../styles";
import { BackButton, ListButton, PagerContext} from "../";
import React, { useContext } from "react";

/**
 * Represents a header component.
 * @param {string} title - The title of the header.
 * @param {string} backUrl - The URL to navigate back.
 * @param {boolean} playAnimation - Indicates whether to play animation or not. Default is true.
 * @returns {JSX.Element} The rendered header component.
 */
export default function Header({title, backUrl, playAnimation = true}) {
  const pager = useContext(PagerContext)
  return (
    <>
      <div className={beheer_logic.headerWrapper}>
        <BackButton url={backUrl || undefined} playAnimation={playAnimation}/>
        <h3 className={[beheer_logic.headerText, beheer_style.text].join(' ')}>{title}</h3>
      </div>
    </>
  )
}

