import { MenuButton } from "../";
import { tegelList_logic } from "../../styles";
import React from "react";
export default function TegelList({data, filter}) {

    return (
        <div className={tegelList_logic.buttonContainer}>
            {GetButtons(data, filter)}
        </div>
    )
}

function GetButtons(data, filter) {
    const filterList = filter || (() => {return true}); // * If filter list is empty, value will be true, so all items will be shown

    if (!data || Object.keys(data).length === 0) return null // * Important to check if the object is empty

    let filteredData = data.filter(filterList);

    if (filteredData.length === 0) return <p style={{marginTop: '25px', fontSize: '.9rem', textAlign: 'center'}} styles={{margin: '0'}}>Geen resultaten gevonden.</p>

    return filteredData.map((item, index) => {
        return <MenuButton key={index} title={typeof item.name === 'function' ? item.name(item) : item.name} callback={() => item.callback()} icon={item.icon}/>
    })
}