const url = 'https://dishmanagement.com'
/**
 * Post data to an endpoint.
 *
 * @param {string} endPoint - The endpoint of the API you are targeting.
 * @param {object} params - The parameters to include in the api request.
 */
export const postRequest = (endPoint, params) => {
  checkForErrors(endPoint, params)
  const data = fetch(`${url}${endPoint}`, {
    method: 'POST',
    credentials: 'include',
    ...params
  })
  .then(response => {
    return response.json();
  })
  .catch(error => {
    console.error(`Error while posting data to ${url}${endPoint} /src/logic/fetchData.js`, error)
    return data
  });
  return data;
}

/**
 * Put data to an endpoint.
 *
 * @param {string} endPoint - The endpoint of the API you are targeting.
 * @param {object} params - The parameters to include in the api request.
 */
export const putRequest = (endPoint, params) => {
  checkForErrors(endPoint, params)
  const data = fetch(`${url}${endPoint}`, {
    method: 'PUT',
    credentials: 'include',
    ...params
  })
  .then(response => {
    if (!response.ok) {
      console.error(`Error while putting data in ${url}${endPoint} /src/logic/fetchData.js`)
      return { success: false }
    } 
    return response.json();
  })
  .catch(error => {
    console.error(`Error while putting data in ${url}${endPoint} /src/logic/fetchData.js`, error)
    return { success: false }
  });
  return data;
}

/**
 * Delete data from an endpoint.
 *
 * @param {string} endPoint - The endpoint of the API you are targeting.
 * @param {object} params - The parameters to include in the api request.
 */
export const deleteRequest = (endPoint, params) => {
  checkForErrors(endPoint, params)
  const data = fetch(`${url}${endPoint}`, {
    method: 'DELETE',
    credentials: 'include',
    ...params
  })
  .then(response => {
    if (!response.ok) {
      console.error(`Error while deleting data from ${url}${endPoint} /src/logic/fetchData.js`)
      return { success: false }
    }
    return response.json();
  })
  .catch(error => {
    console.error(`Error while deleting data from ${url}${endPoint} /src/logic/fetchData.js`, error)
    return { success: false }
  });
  return data;
}


export const fetchRequest = (endPoint, params) => {
  checkForErrors(endPoint, params)
  const data = fetch(`${url}${endPoint}`, {
    method: 'GET',
    credentials: 'include',
    ...params
  })
  .then(response => {
    if (!response.ok) {
      console.error(`Error while getting data from ${url}${endPoint} /src/logic/fetchData.js`)
      return { success: false }
    }
    return response.json();
  })
  .catch(error => {
    console.error(`Error while getting data from ${url}${endPoint} /src/logic/fetchData.js`, error)
    return { success: false }
  });
  return data;
}

export const fetchMedia = (endPoint, params) => {
  checkForErrors(endPoint, params)
  const data = fetch(`${url}${endPoint}`, {
    method: 'GET',
    credentials: 'include',
    ...params
  })
  .then(response => {
    if (!response.ok) {
      console.error(`Error while getting media from ${url}${endPoint} /src/logic/fetchData.js`)
      return { success: false }
    }
    return response;
  })
  .catch(error => {
    console.error(`Error while getting media from ${url}${endPoint} /src/logic/fetchData.js`, error)
    return { success: false }
  });
  return data;
}


const checkForErrors = (endPoint, params) => {
  if (endPoint && !endPoint.includes('/')) {
    throw new Error(`endPoint cannot be left blank and must include an '/'. /src/logic/fetchData.js`)
  }
  if (typeof params !== 'object') {
    throw new Error(`Params must be an object. /src/logic/fetchData.js`)
  }
}