import { popup_logic, popup_style } from "../../styles"

export function Popup({ children, toggle, style }) {
  return (
    <>
      <div onKeyUp={(e) => { e.key === 'Escape' && toggle(false) }} className={[popup_logic.blur, popup_style.blur].join(' ')}>
        <div onClick={() => toggle(false)} className={popup_logic.overlay}></div>
        <div className={[popup_logic.panel, popup_style.panel].join(' ')} style={style}>
          {children}
        </div>
      </div>
    </>
  )
}
