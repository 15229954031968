import { useContext, useEffect, useState } from "react";
import { Header, PagerContext, ResultList, Search, Button, ListButton } from "../../../components";
import { DataStorageContext } from "../../../logic";
export default function Organisatie() {
  const pager = useContext(PagerContext)
  const { readData } = useContext(DataStorageContext)
  const [searchValue, setSearchValue] = useState('')
  const [organisaties, setOrganisaties] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      await pager.allowed_on_page()
      setOrganisaties(await readData('beheer_organisaties'))
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

	return (
		<>
      <Header 
        title="Organisaties"
        backUrl="/beheer/applicatie"
        playAnimation={false}
      />

      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'end', width: '100%'}}>
        <div style={{width: '65%'}}><Search title="Zoeken" placeholder="Typ om te zoeken" value={searchValue} setValue={setSearchValue}/></div>
        <div style={{width: '30%'}}><Button title="Toevoegen" type="primary" styles={{height: '34px', width: '100%', fontSize: '1rem'}} callback={() => pager.navigateTo('/beheer/applicatie/organisatie/nieuw')}/></div>
      </div>

      <ResultList 
        title={(item) => item.naam}
        data={organisaties.values} 
        url={(organisatie) => {return `/beheer/applicatie/organisatie/edit?organisatie=${organisatie.organisatieID}`}} 
        filter={(organisaties) => {
          const searchParts = searchValue.toLowerCase().split(' ').filter(part => part.trim() !== '');
          return searchParts.length === 0 ||
            searchParts.every(part =>
              String(organisaties.organisatieID).toLowerCase().includes(part) ||
              organisaties.naam.toLowerCase().includes(part) ||
              organisaties.adres.toLowerCase().includes(part) ||
              organisaties.huisnummer.toLowerCase().includes(part) ||
              organisaties.postcode.toLowerCase().includes(part) ||
              organisaties.plaats.toLowerCase().includes(part) ||
              organisaties.contactpersoon.toLowerCase().includes(part)
            );
          }} 
      />
		</>
	);
}
