import React, { createContext, useMemo, useContext } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import { Config, DefaultPage } from "../../pageConfig";
import { Background, Animations } from ".."
import { DataStorageContext } from "../../logic"
import { page_logic, page_style } from "../../styles";

export const PagerContext = createContext()

export function PagerProvider() {
  const dataStorage = useContext(DataStorageContext);
  const location = useLocation()
  const navigate = useHistory();
  // const [breadcrumbs, setBreadcrumbs] = React.useState()
  const currentPage = useMemo(() => {
    const pageInConfig = Object.values(Config).find((value) => {
      return value.url === location.pathname;
    });
    if (!pageInConfig) { // * Page does not exist in pageConfig
      navigate.push(DefaultPage.url);
      return DefaultPage;
    }
    document.title = pageInConfig.name || 'Kwaliteit@ App'
    // setBreadcrumbs(await generateBreadCrumb())
    return pageInConfig;
  }, [location, navigate]);

  // useEffect(() => {
  //   const generateBreadCrumb = async () => {
  //     let locatie;
  //     if (!currentPage.breadcrumb) return setBreadcrumbs(null);
  //     let locatieID = new URLSearchParams(window.location.hash.split('?')[1]).get('locatie') || null
  //     if (locatieID) {
  //       const locaties = (await dataStorage.readData('beheer_locaties'))
  //       if (!locaties.success) return null
  //       locatie = locaties.values.find((locatie) => locatie.locatieID === parseInt(locatieID))
  //     } else locatie = null

  //     const breadcrumbs = typeof currentPage.breadcrumb === 'function' ? currentPage.breadcrumb(locatie, locatieID) : currentPage.breadcrumb;
  //     setBreadcrumbs(
  //       <p className={[page_logic.breadcrumb, page_style.breadcrumb].join(' ')}>
  //         {breadcrumbs.map((crumb, index) => {
  //           return (
  //             <span key={index}>
  //               {crumb.url ? ( // eslint-disable-next-line
  //                 <a className={[page_logic.hasLink, page_style.hasLink].join(' ')} onClick={(e) => { e.preventDefault(); navigate.push(crumb.url); }}>{crumb.naam}</a>
  //               ) : ( // eslint-disable-next-line
  //                 <a className={page_logic.active}>{crumb.naam}</a>
  //               )}
  //               {index < breadcrumbs.length - 1 ? ' > ' : ''}
  //             </span>
  //           );
  //         })}
  //       </p>
  //     );
  //   }
  //   generateBreadCrumb()
  //   // eslint-disable-next-line
  // }, [location, navigate])

  const context = {
    current_page: currentPage,
    arguments: location.search,
    allowed_on_page: async () => {
      let Page = Object.values(Config).find((config) => config.url === window.location.href.split('#')[1].split('?')[0])
      if (!Page) { console.error("Page does not exist in PageConfig!"); navigate.push(DefaultPage.url); return {allowed: false, reason: 'Page does not exist in PageConfig!'}}
      const permission = await dataStorage.readData('permission')
      if (!permission.success || permission.values.rechtengroep === undefined) { navigate.push(DefaultPage.url); return {allowed: false, reason: 'Permission list is undefined!'} };
      if (Page.permission.includes(permission.values.rechtengroep) || !Page.permission) { return {allowed: true, arguments: location.search, ...Page};} 
      else { navigate.push(DefaultPage.url); return {allowed: false, reason: 'Permission does not exist on user profile!'}}
    },
    signout: () => {
      clearAllCookies();
      dataStorage.clearAll();
      navigate.push(DefaultPage.url);
    },
    navigateTo: (url) => {
      navigate.push(url);
    },
    getArgument: (key) => {
      return new URLSearchParams(window.location.hash.split('?')[1]).get(key);
    }
  }

  return <PagerContext.Provider value={context}>
    <div className={[page_logic.page, page_style.page].join(' ')}>
      <Animations/>
      <Background/>
      <div className={page_logic.pageContainer}>
        {
          Object.values(Config).map((page) => {
            return <Route key={page.url} path={page.url} exact>
              <div style={{position: 'relative', display: 'block', margin: 'auto', width: '95%', height: '100%', zIndex: '100'}}>
                  <page.component />
              </div>
            </Route>
          })
        }
      </div>
    </div>
  </PagerContext.Provider>
}

function clearAllCookies() {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}