import { PagerContext, Header, Steps, ListButton, Input, Button, NotificationContext} from '../components/';
import React, { useContext, useEffect, useState } from 'react';
import { DataStorageContext, postRequest } from '../logic';
import { StarIcon } from '../Images';
export default function Feedback() {
    const pager = useContext(PagerContext);
    const { readData } = useContext(DataStorageContext)  
    const notificationController = useContext(NotificationContext);
    const [data, setData] = useState({pagina: '', feedback: ''});
    const [permission, setPermission] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            pager.allowed_on_page()
            setPermission((await readData('permission')).values.rechtengroep)
        }
        fetchData()
    }, [])

    useEffect(() => {console.log(data)}, [data])

    const saveFeedback = async (rating = 0) => {
        let result;
        const checkResult = checkInput(data)
        if (!checkResult.success) return notificationController.sendMessage({type: 'error', message: checkResult.message})
        await postRequest('/api/data/feedback', {
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                pagina: data.pagina,
                feedback: data.feedback,
                rating: rating
            })
        }).then((response) => {result = response})
        if (!result.success) return notificationController.sendMessage({type: 'error', message: 'Er is iets fout gegaan tijdens het aanmaken van de locatie. Probeer het opnieuw.'})
        notificationController.sendMessage({type: 'success', message: 'Bedankt voor uw feedback!'})
        pager.navigateTo('/dashboard')
    }

    return (
        <>
            <Header title="Feedback"/>
            <Steps steps={[
                {
                    title: 'Kies een pagina.',
                    content: (handleSwipe) => (
                        <>
                            {getPages(permission, setData, handleSwipe)}
                        </>
                    )
                },
                {
                    title: 'Geef uw feedback.',
                    content: (handleSwipe) => (
                        <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%'}}>
                            <Input charLimit={2000} hideLimit placeholder="Uw feedback..." name="feedback" styles={{backgroundColor: '#e6e0ec', borderRadius: '2px', color: data?.feedback?.length >= 2000 ? 'var(--error-color)' : 'unset'}} rows={16} value={data?.feedback} setValue={setData}/>
                            <Button type="primary" styles={{width: '100%'}} title="Volgende" callback={() => handleSwipe('left')}/>
                        </div>
                    )
                },
                {
                    title: 'Hoe tevreden bent u met de app?',
                    content: (
                        <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '95%'}}>
                            <div style={{color: 'var(--primary-text-color)', width: '100%', textAlign: 'center'}}>Klik op een van de sterren</div>
                            <div>
                                <div style={{display: 'flex', justifyContent: 'space-between', width: 'calc(100% - 40px)', background: '#1a356e', borderRadius: '14px', marginBottom: '10px', padding: '10px 20px'}}>
                                    <StarIcon onClick={() => saveFeedback(1)} style={{width: '45px', fill: '#FF4C4C'}}/>
                                    <StarIcon onClick={() => saveFeedback(2)} style={{width: '45px', fill: '#FFA500'}}/>
                                    <StarIcon onClick={() => saveFeedback(3)} style={{width: '45px', fill: '#FFD700'}}/>
                                    <StarIcon onClick={() => saveFeedback(4)} style={{width: '45px', fill: '#9ACD32'}}/>
                                    <StarIcon onClick={() => saveFeedback(5)} style={{width: '45px', fill: '#4CAF50'}}/>
                                </div>
                                <Button type="secondary" styles={{width: '100%', color: 'white'}} title="Overslaan" callback={() => saveFeedback(0)}/>
                            </div>
                        </div>
                    )
                }
            ]}/>
        </>
    )
}

function getPages(permission, setData, handleSwipe) {
    let pages = []
    if ([1, 2].includes(permission)) pages = ['Beloven', 'Beleven', 'Bekijken', 'Beheer - Algemeen', 'Beheer - Organisaties', 'Beheer - Locaties', 'Beheer - Accounts', 'Beheer - Bewoners', 'Beheer - Logs', 'Beheer - Beloftes', 'Overige']
    if (3 === permission)            pages = ['Beloven', 'Beleven', 'Bekijken', 'Beheer - Algemeen', 'Beheer - Accounts', 'Beheer - Bewoners', 'Beheer - Logs', 'Beheer - Beloftes', 'Overige']
    if (4 === permission)            pages = ['Beloven', 'Beleven', 'Bekijken', 'Overig']
    if (6 === permission)            pages = ['Beloven', 'Bekijken', 'Overig']

    return pages.map((page, index) => (
        <ListButton key={index} title={page} callback={() => {setData(prev => ({...prev, pagina: page})); handleSwipe('left')}}/>
    ))
}

function checkInput(data) {
    if (!data.pagina) {return {success: false, message: 'Selecteer een pagina.'}}
    if (!data.feedback) {return {success: false, message: 'Er is geen feedback opgegeven.'}}
    return {success: true}   
}