import { input_logic, input_style } from "../../../styles";
import { RequiredIcon } from "../../../Images";

/**
 * Input component.
 * @param {string} props.title - The title of the input component.
 * @param {string} props.placeholder - The placeholder text for the input component.
 * @param {string} props.value - The value of the input component.
 * @param {function} props.setValue - The function to set the value of the input component.
 * @param {string} props.name - The name of the input component.
 * @param {Object} props.styles - The styles to apply to the input component.
 * @param {number} props.charLimit - The character limit for the input value.
 * @param {boolean} props.hideLimit - Indicates if the character limit should be hidden.
 * @param {number|string} props.rows - The number of rows for a textarea input. 3
 * @param {number} props.tabIndex - The tab index of the input component.
 * @param {boolean} props.disabled - Indicates if the input is disabled.
 * @param {boolean} props.required - Indicates if the input is required.
 * @returns {JSX.Element} The rendered input component.
 */
export default function Checkbox({ title, styles, tabIndex, placeholder, value, setValue, required, disabled, name }) {
  let element_styles = {};
  if (styles || typeof styles === "object") element_styles = styles

  const onValueChange = (e) => {
    const value = e.target.checked
    if (name) {
        setValue(prev => ({
          ...prev,
          [name]: value
        }))
    } else setValue(value)
  }

  return (
    <div className={disabled ? input_logic.disabled : ''}>
      <label className={[input_logic.text, input_style.text].join(" ")}>
        <span>{title}</span> 
        {required && <RequiredIcon className={[input_style.required, input_logic.required].join(" ")}/>}
        <input 
          disabled={disabled}
          tabIndex={tabIndex} 
          type="checkbox" 
          style={element_styles} 
          checked={value} 
          onChange={onValueChange}
        ></input>
      </label>
      <p className={[input_logic.checkBoxToolTip, input_style.checkBoxToolTip].join(' ')}>{placeholder}</p>
    </div>
  )
}
