import { useContext, useEffect, useState } from "react";
import { Header, PagerContext, ResultList, Search, Button } from "../../../components";
import { DataStorageContext } from "../../../logic";
export default function Bewoners() {
  const pager = useContext(PagerContext)
  const { readData } = useContext(DataStorageContext)
  const [searchValue, setSearchValue] = useState('') 
  const [bewoners, setBewoners] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      await pager.allowed_on_page()
      setBewoners(await readData('beheer_bewoners'))
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header 
        title="Bewoners"
        backUrl={`/beheer/locatie?locatie=${pager.getArgument('locatie')}`}
        playAnimation={false}
      />

      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'end', width: '100%'}}>
        <div style={{width: '65%'}}><Search title="Zoeken" placeholder="Typ om te zoeken" value={searchValue} setValue={setSearchValue}/></div>
        <div style={{width: '30%'}}><Button title="Toevoegen" type="primary" styles={{height: '34px', width: '100%', fontSize: '1rem'}} callback={() => pager.navigateTo(`/beheer/locatie/bewoners/nieuw?locatie=${pager.getArgument('locatie')}`)}/></div>
      </div>

      <ResultList 
        title={(item) => item.naam}
        showResults        
        data={bewoners.values} 
        url={(bewoner) => {return `/beheer/locatie/bewoners/edit?bewoner=${bewoner.bewonerID}&locatie=${pager.getArgument('locatie')}`}} 
        filter={(bewoners) => {
          const searchParts = searchValue.toLowerCase().split(' ').filter(part => part.trim() !== '');
          return searchParts.length === 0 ||
            searchParts.every(part =>
              bewoners.naam.toLowerCase().includes(part) ||
              (new Date(bewoners.geboortedatum).toLocaleDateString('nl-NL', { day: '2-digit', month: '2-digit', year: 'numeric' })).includes(part)
            );
          }}
      />
    </>
  );
}
