import { useState, useContext } from "react";
import { delete_logic, delete_style } from "../../../styles";
import { Popup, Button, NotificationContext } from "../../";
import { deleteRequest, DataStorageContext } from "../../../logic";
import { DeleteIcon } from "../../../Images";

/**
 * Represents a component for deleting an item.
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the item to be deleted.
 * @param {string} props.endpoint - The endpoint for the delete request.
 * @param {string} props.id - The ID of the item to be deleted.
 * @param {Array||String} props.storageName - The name of the storage for clearing data.
 * @param {Function} props.callback - The callback function to be called after successful deletion.
 * @returns {JSX.Element} The delete component.
 */
export default function Delete({ title, endpoint, id, storageName, callback, buttonStyles }) {
    const [showPopup, setShowPopup] = useState(false);
    const { clearData, readData } = useContext(DataStorageContext);
    const notificationController = useContext(NotificationContext);

    let button_element_styles = {};
    if (buttonStyles || typeof buttonStyles === "object") button_element_styles = buttonStyles

    const deleteItem = async () => {

      if (!endpoint) return notificationController.sendMessage({type: 'error', message: `Development error occured for '${title}' - endpoint missing.`})
      if (!id) return notificationController.sendMessage({type: 'error', message: `Development error occured for '${title}' - id missing.`})
      if (!storageName) return notificationController.sendMessage({type: 'error', message: `Development error occured for '${title}' - storageName missing.`})
      if (typeof id !== 'number') return notificationController.sendMessage({type: 'error', message: `Development error occured for '${title}' - ID is not a number.`})

      let data;
      await deleteRequest(endpoint, {
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: id
          })
        }).then((response) => {data = response})
        if (!data.success) return notificationController.sendMessage({type: 'error', message: 'Er is iets fout gegaan tijdens het verwijderen van het item.'})
        if (!Array.isArray(storageName)) { clearData(storageName); readData(storageName) }
        else {
          for (const item in storageName) {
            clearData(storageName[item])
            readData(storageName[item])
          }
        }

        notificationController.sendMessage({type: 'success', message: 'Het item is succesvol verwijderd.'})
        if (callback) callback()
    }

    return (
        <>
            <Button 
                type="primary" 
                title={<div><DeleteIcon style={{marginRight: '5px', fill: 'var(--info-color)'}}/>Verwijderen</div>} 
                callback={() => setShowPopup(true)}
                styles={button_element_styles}
            />
            {showPopup && renderPopup(title, setShowPopup, deleteItem)}
        </>
    )

}

function renderPopup(title, setShowPopup, deleteItem) {
    return (
        <div className={delete_logic.fadeIn}>
          <Popup toggle={setShowPopup}>
            <h1 className={[delete_logic.headerText, delete_style.text].join(" ")}>Weet je zeker dat je het volgende wilt verwijderen?</h1>
            <p className={[delete_logic.itemText, delete_style.text].join(" ")}>{title}</p>
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '30px'}}>
              <Button type="secondary" title="Verwijderen" callback={deleteItem} styles={{fontSize: '.9rem'}}/>
              <Button type="secondary" title="Annuleren" callback={() => setShowPopup(false)} styles={{fontSize: '.9rem'}}/>
            </div>
          </Popup>
        </div>
      )
}