import { Beloven, Beleven, Login, Privacy, Beheer_feedback, Beheer, Bekijken, Locatie, Applicatie, Organisatie, OrganisatieEditor, OrganisatieNieuw, ApplicatieLocatie, LocatieEditor, LocatieNieuw, Accounts, Reset, AccountNieuw, AccountEditor, Bewoners, BewonersNieuw, BewonersEditor, BekijkRapportage, Belofte, Logs, Dashboard } from "./pages/"
import { AdminIcon, BelovenIcon, EyeIcon, SearchIcon, ChatIcon } from './Images'
import Feedback from "./pages/Feedback"

const throwError = (message) => {
  throw new Error(`${message}`)
}

export const Config = {
  Login: {
    url: '/',
    name: "Login",
    icon: undefined,
    title: false,
    component: Login,
    permission: false,
    showNavbar: false,
    showInNavbar: false,
  },
  Reset: {
    url: '/reset',
    name: "Reset Wachtwoord",
    icon: undefined,
    title: false,
    component: Reset,
    permission: false,
    showNavbar: false,
    showInNavbar: false,
  },
  Privacy: {
    url: '/privacy',
    name: "Privacy",
    icon: undefined,
    title: false,
    component: Privacy,
    permission: false,
    showNavbar: false,
    showInNavbar: false,
  },
  Dashboard: {
    url: '/dashboard',
    name: "Dashboard",
    icon: undefined,
    title: 'Dashboard',
    component: Dashboard,
    permission: [1, 2, 3, 4, 5, 6, 7],
    showInNavbar: false,
  },
  Beloven: {
    url: '/beloven',
    name: "Beloven",
    icon: BelovenIcon,
    title: 'Beloven',
    component: Beloven,
    permission: [1, 2, 3, 4, 5, 6, 7],
    showNavbar: true,
    showInNavbar: true,
  },
  Beleven: {
    url: '/beleven',
    name: "Beleven",
    icon: EyeIcon,
    title: 'Beleven',
    component: Beleven,
    permission: [1, 2, 3, 4],
    showNavbar: true,
    showInNavbar: true,
  },
  bekijken: {
    url: '/bekijken',
    name: "Bekijken",
    icon: SearchIcon,
    title: 'Bekijken',
    component: Bekijken,
    permission: [1, 2, 3, 4, 5, 6],
    showNavbar: true,
    showInNavbar: true,
  },
  bekijkRapportage: {
    url: '/bekijken/rapportage',
    name: "Bekijken",
    icon: undefined,
    title: 'Bekijken',
    component: BekijkRapportage,
    permission: [1, 2, 3, 4, 5, 6],
    showNavbar: true,
    showInNavbar: false,
  },
  Beheer: {
    url: '/beheer',
    name: "Beheren",
    icon: AdminIcon,
    title: 'Beheer',
    component: Beheer,
    permission: [1, 2, 3],
    showNavbar: true,
    showInNavbar: true,
  },
  Feedback: {
    url: '/feedback',
    name: "Feedback",
    icon: ChatIcon,
    title: 'Feedback',
    component: Feedback,
    permission: [1, 2, 3, 4, 5, 6],
    showNavbar: true,
    showInNavbar: true,
  },
  Beheer_applicatie: {
    url: '/beheer/applicatie',
    name: "Beheer - Applicatie",
    icon: undefined,
    title: 'Beheer',
    component: Applicatie,
    permission: [1, 2],
    showNavbar: true,
    showInNavbar: false,
  },
  Beheer_applicatie_organisatie: {
    url: '/beheer/applicatie/organisatie',
    name: "Beheer - Organisatie",
    icon: undefined,
    title: 'Beheer',
    component: Organisatie,
    permission: [1, 2],
    showNavbar: true,
    showInNavbar: false,
  },
  Beheer_applicatie_organisatie_editor: {
    url: '/beheer/applicatie/organisatie/edit',
    name: "Beheer - Organisatie",
    icon: undefined,
    title: 'Beheer',
    component: OrganisatieEditor,
    permission: [1, 2],
    showNavbar: true,
    showInNavbar: false,
  },
  Beheer_applicatie_organisatie_nieuw: {
    url: '/beheer/applicatie/organisatie/nieuw',
    name: "Beheer - Organisatie",
    icon: undefined,
    title: 'Beheer',
    component: OrganisatieNieuw,
    permission: [1, 2],
    showNavbar: true,
    showInNavbar: false,
  },
  Beheer_applicatie_locatie: {
    url: '/beheer/applicatie/locatie',
    name: "Beheer - Locatie",
    icon: undefined,
    title: 'Beheer',
    component: ApplicatieLocatie,
    permission: [1, 2],
    showNavbar: true,
    showInNavbar: false,
  },
  Beheer_applicatie_locatie_editor: {
    url: '/beheer/applicatie/locatie/edit',
    name: "Beheer - Locatie",
    icon: undefined,
    title: 'Beheer',
    component: LocatieEditor,
    permission: [1, 2],
    showNavbar: true,
    showInNavbar: false,
  },
  Beheer_applicatie_locatie_nieuw: {
    url: '/beheer/applicatie/locatie/nieuw',
    name: "Beheer - Locatie",
    icon: undefined,
    title: 'Beheer',
    component: LocatieNieuw,
    permission: [1, 2],
    showNavbar: true,
    showInNavbar: false,
  },
  Beheer_feedback: {
    url: '/beheer/applicatie/feedback',
    name: "Beheer - Feedback",
    icon: undefined,
    title: 'Beheer',
    component: Beheer_feedback,
    permission: [1, 2],
    showNavbar: true,
    showInNavbar: false,
  },
  Beheer_locatie: {
    url: '/beheer/locatie',
    name: "Beheer - Locatie",
    icon: undefined,
    title: 'Beheer',
    component: Locatie,
    permission: [1, 2, 3],
    showNavbar: true,
    showInNavbar: false,
  },
  Beheer_locatie_account: {
    url: '/beheer/locatie/accounts',
    name: "Beheer - Accounts",
    icon: undefined,
    title: 'Beheer',
    component: Accounts,
    permission: [1, 2, 3],
    showNavbar: true,
    showInNavbar: false,
  },
  Beheer_locatie_account_nieuw: {
    url: '/beheer/locatie/accounts/nieuw',
    name: "Beheer - Accounts",
    icon: undefined,
    title: 'Beheer',
    component: AccountNieuw,
    permission: [1, 2, 3],
    showNavbar: true,
    showInNavbar: false,
  },
  Beheer_locatie_account_editor: {
    url: '/beheer/locatie/accounts/edit',
    name: "Beheer - Accounts",
    icon: undefined,
    title: 'Beheer',
    component: AccountEditor,
    permission: [1, 2, 3],
    showNavbar: true,
    showInNavbar: false,
  },
  Beheer_locatie_bewoners: {
    url: '/beheer/locatie/bewoners',
    name: "Beheer - Bewoners",
    icon: undefined,
    title: 'Beheer',
    component: Bewoners,
    permission: [1, 2, 3],
    showNavbar: true,
    showInNavbar: false,
  },
  Beheer_locatie_bewoners_nieuw: {
    url: '/beheer/locatie/bewoners/nieuw',
    name: "Beheer - Bewoners",
    icon: undefined,
    title: 'Beheer',
    component: BewonersNieuw,
    permission: [1, 2, 3],
    showNavbar: true,
    showInNavbar: false,
  },
  Beheer_locatie_bewoners_editor: {
    url: '/beheer/locatie/bewoners/edit',
    name: "Beheer - Bewoners",
    icon: undefined,
    title: 'Beheer',
    component: BewonersEditor,
    permission: [1, 2, 3],
    showNavbar: true,
    showInNavbar: false,
  },
  Beheer_locatie_logs: {
    url: '/beheer/locatie/logs',
    name: "Beheer - Logs",
    icon: undefined,
    title: 'Beheer',
    component: Logs,
    permission: [1, 2, 3],
    showNavbar: true,
    showInNavbar: false,
  },
  Beheer_locatie_beloftes: {
    url: '/beheer/locatie/beloftes',
    name: "Beheer - Beloftes",
    icon: undefined,
    title: 'Beheer',
    component: Belofte,
    permission: [1, 2, 3],
    showNavbar: true,
    showInNavbar: false,
  },
}

export const DefaultPage = Object.values(Config).find(page => page.url === '/')
  || throwError("No page with path `/` found on page config!")