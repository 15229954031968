// import { useState } from "react";

// /* // ! Issue:
// *  // The swipe div has to be fullSize and on top to read the swipes
// *  // This blocks the interaction with the elements below
// *  // The swipe div is not visible, but it is there
// *  // Please find a solution to this issue
// *  // The 'pointerEvents: none' property does not work,
// *  // Maybe you can change the propperty during the swipe reading process...

// *  // Maybe if the div is lowest it will work...
// */

// export default function Swiper({ children, callBackLeft, callBackRight }) {
//     const [touchStartX, setTouchStartX] = useState(null);
//     const [touchStartY, setTouchStartY] = useState(null);
//     const [touchEndX, setTouchEndX] = useState(null);
//     const [touchEndY, setTouchEndY] = useState(null);
//     const minSwipeDistance = 100;

//     const onTouchStart = (e) => {
//         setTouchEndX(null); // otherwise the swipe is fired even with usual touch events
//         setTouchEndY(null);
//         setTouchStartX(e.targetTouches[0].clientX);
//         setTouchStartY(e.targetTouches[0].clientY);
//     };

//     const onTouchMove = (e) => {
//         setTouchEndX(e.targetTouches[0].clientX);
//         setTouchEndY(e.targetTouches[0].clientY);
//     };

//     const onTouchEnd = () => {
//         if (!touchStartX || !touchEndX || !touchStartY || !touchEndY) return;
//         const distanceX = touchStartX - touchEndX;
//         const distanceY = touchStartY - touchEndY;
//         const isLeftSwipe = distanceX > minSwipeDistance;
//         const isRightSwipe = distanceX < -minSwipeDistance;

//         if (isLeftSwipe && Math.abs(distanceX) > Math.abs(distanceY)) {
//             callBackLeft();
//         }
//         if (isRightSwipe && Math.abs(distanceX) > Math.abs(distanceY)) {
//             callBackRight();
//         }
//     };

//     return (
//         <>
//             <div 
//                 style={{ 
//                     // position: 'fixed', 
//                     // top: 0, 
//                     // left: 0, 
//                     width: '100%', 
//                     height: '100%', 
//                     zIndex: 1, 
//                 }} 
//                 onTouchStart={onTouchStart} 
//                 onTouchMove={onTouchMove} 
//                 onTouchEnd={onTouchEnd}
//             >
//                 {children}
//             </div>
//         </>
//     );
// }

import {useState} from "react";

export default function useSwipe(input) {
    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);

    const minSwipeDistance = 50;

    const onTouchStart = (e) => {
        setTouchEnd(0); // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX);
    }

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        if (isLeftSwipe) {
            input.onSwipedLeft();
        }
        if (isRightSwipe) {
            input.onSwipedRight();
        }
    }

    return {
        onTouchStart,
        onTouchMove,
        onTouchEnd
    }
}