import { ListButton } from "../";
import { PagerContext } from "../";
import { beheer_logic, beheer_style } from "../../styles";
import React, { useContext } from "react";

/**
 * Retrieves filtered data based on the provided filter function.
 *
 * @param {Array} data - The data array to filter.
 * @param {Function} filter - The filter function to apply on the data array.
 * @param {object} styles - The styles object for the list items
 * @param {string} url - The URL to navigate to when a list item is clicked.
 * @param {number} limit - The maximum number of items to display.
 * @param {string|Function} title - The title of each list item, can be a string or a function that returns a string.
 * @returns {Array} - The filtered data array with list items.
 */
export default function ResultList({data, styles, filter, url, limit, title, showResults}) {

    return (
        <>
            {showResults && <p className={[beheer_logic.text, beheer_style.text].join(' ')}>Resultaten</p>}
            {data && GetData(data, styles, filter, url, title, limit)}
        </>
    )
}

function GetData(data, styles, filter, url, title, limit) {
    const pager = useContext(PagerContext)

    const filterList = filter || (() => {return true}); // * If filter list is empty, value will be true, so all items will be shown

    if (Object.keys(data).length === 0) return null // * Important to check if the object is empty

    let filteredData = data.filter(filterList);

    if (filteredData.length === 0) return <p style={{marginTop: '25px', fontSize: '.9rem', textAlign: 'center'}} className={[beheer_logic.text, beheer_style.text].join(' ')}>Geen resultaten gevonden.</p>

    return filteredData.map((item, index) => {
        if (index > limit) return null
        return <ListButton key={index} styles={styles} title={typeof title === 'function' ? title(item) : title || item.naam} callback={() => url ? pager.navigateTo(url(item)) : null}/>
    })
}