import React, { useEffect, useState, useContext } from "react";
import { animations_logic, animations_style } from "../../styles";
import { DataStorageContext } from "../../logic";

export default function Animations() {
    const [pageTransVisible, setPageTransVisible] = useState(false);
    const [pageOutTransVisible, setPageOutTransVisible] = useState(false);
    const [animation, setAnimation] = useState({});
    const { readData, data, clearData } = useContext(DataStorageContext);

    useEffect(() => {

        const fetchData = async () => {
            const animation = await readData("animation");
            if (!animation.success) return;
            setAnimation(animation)
            if (animation.values.name === "page_transition") {
                setPageTransVisible(true);
            }
            if (animation.values.name === "page_out_transition") {
                setPageOutTransVisible(true);
            }
            clearData('animation')
        }

        fetchData();
// eslint-disable-next-line
    }, [data.animation]);

    if (pageTransVisible) return (
        <>
            <div id="animationContainer" className={[animations_logic.animationContainer, animations_logic.white, animations_style.white].join(' ')}
                onAnimationEnd={() => {
                    if (animation.values.callback) {
                        animation.values.callback();
                    }
                }}
            ></div>
            <div id="animationContainer" className={[animations_logic.animationContainer, animations_logic.purple, animations_style.purple].join(' ')}></div>
            <div id="animationContainer" className={[animations_logic.animationContainer, animations_logic.blue, animations_style.blue].join(' ')}></div>
            <div 
                id="animationContainer_last" 
                className={[animations_logic.animationContainer, animations_logic.lightblue, animations_style.lightblue].join(' ')} 
                onAnimationEnd={() => {
                    setPageTransVisible(false);
                }}
            ></div>        
        </>
    )

    if (pageOutTransVisible) return (
        <>
            <div id="animationContainer" className={[animations_logic.out_animationContainer, animations_logic.out_white, animations_style.white].join(' ')}
                onAnimationEnd={() => {
                    if (animation.values.callback) {
                        animation.values.callback();
                    }
                }}
            ></div>
            <div id="animationContainer" className={[animations_logic.out_animationContainer, animations_logic.out_purple, animations_style.purple].join(' ')}></div>
            <div id="animationContainer" className={[animations_logic.out_animationContainer, animations_logic.out_blue, animations_style.blue].join(' ')}></div>
            <div 
                id="animationContainer_last" 
                className={[animations_logic.out_animationContainer, animations_logic.out_lightblue, animations_style.lightblue].join(' ')} 
                onAnimationEnd={() => {
                    setPageOutTransVisible(false);
                }}
            ></div>        
        </>
    )
}