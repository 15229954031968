import React, { useContext, useEffect, useState } from "react";
import { PagerContext, BackButton, Search, Button, ResultList, DateInput, Input, Steps, NotificationContext } from "../components";
import { DataStorageContext, postRequest, fetchMedia } from "../logic";
import { DeleteIcon, BelovenIcon, UserIcon } from "../Images";
import { belofte1, belofte2, belofte3, belofte4, belofte5, belofte6, belofte7, belofte8, belofte9, belofte10 } from "../Images";
import { beleven_logic, beleven_style, bekijken_logic, bekijken_style } from "../styles";
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

export default function Beleven() {
  const imageInputRef = React.useRef(null)
  const imageRef = React.useRef(null)
  const pager = useContext(PagerContext)
  const { readData, saveData, clearData } = useContext(DataStorageContext)
  const notificationController = useContext(NotificationContext);

  const [image, setImage] = useState(null)
  const [video, setVideo] = useState(null)
  const [crop, setCrop] = useState()
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);

  const [beloften, setBeloften] = useState([])
  const [bewoners, setBewoners] = useState([])

  const [searchValue, setSearchValue] = useState('')

  const [selectedBelofte, setSelectedBelofte] = useState()
  const [selectedBewoner, setSelectedBewoner] = useState()

  const [datum, setDatum] = useState(getCurrentDateTime())
  const [verslag, setVerslag] = useState('')

  const [requestSend, setRequestSend] = useState(false)
  const [limit, setLimit] = useState(10)
  // const [loadMore, setLoadMore] = useState(false)
// eslint-disable-next-line
  const [uploadComplete, setUploadComplete] = useState(false)
  const [filePath, setFilePath] = useState('')
  let loadMore = false;

  useEffect(() => {
    const fetchData = async () => {
      await pager.allowed_on_page()

      let _bewoners = await readData('bewoners')
      fetchBewoners(_bewoners)
      setBewoners(_bewoners)

      const beloftes = await readData('beloftes')
      // setSelectedBelofte(beloftes.values[0].belofteID)
      setBeloften(beloftes)
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchBewoners()
    setLimit(8)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  const fetchBewoners = async (bewonerList) => {
    let index = 0;
    bewonerList = bewonerList ? bewonerList : bewoners
    
    if (!bewonerList.success) return;
    const _bewoners = await Promise.all(bewonerList.values.map(async (bewoner) => {
      if (index >= 10) return bewoner
      if (searchValue && !bewoner.naam.toLowerCase().includes(searchValue.toLowerCase())) return bewoner;
      if (typeof bewoner.profielfoto === 'string' && !bewoner.profielfoto.startsWith('blob')) {
        index = index + 1
        const data = await fetchMedia(`/api/data/media?${new URLSearchParams({id: bewoner.profielfoto})}`, {})
        if (data.status === 500) { bewoner.profielfoto = null; return bewoner}
        const blob = await data.blob()
        bewoner.profielfoto = URL.createObjectURL(blob)
      }
      return bewoner
    }))

    setBewoners({ success: true, values: _bewoners });
    await saveData('bewoners', _bewoners );

    return true;
  }

  const generateBeloftes = (handleSwipe) => {
    return (
      <>
        {beloften.success && <ResultList showResults data={beloften.values} limit={25} title={(belofte) => 
            <div style={{display: "flex", alignItems: 'center', height: '26px'}} onClick={() => {setSelectedBelofte(belofte.belofteID); handleSwipe('left')}}>
              <img src={getIcon(belofte.nummer)} style={{width: '18px', overflow: 'hidden', marginRight: '10px'}}></img>
              <div style={{width: '100%', margin: '0', textWrap: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{belofte.nummer}. {belofte.belofte}</div>
              <p style={{margin: '0', marginLeft: '10px'}}></p>
            </div>
        } />}
      </>
    )
  }


  const onImageChange = async (e) => {
    const file = e.target.files[0];
    
    if (!file) {
      setImage(null)
      setVideo(null)
      setCroppedImageUrl(null)
      setCrop()
      return null;
    }

    if (file.type !== 'image/png' && file.type !== 'image/jpeg' && file.type !== 'video/mp4') {
      return notificationController.sendMessage({type: 'error', message: `Ongeldig bestandstype. Alleen PNG, JPEG en MP4 bestanden zijn toegestaan.`});
    }

    if (file.size > 200000000) {
      return notificationController.sendMessage({type: 'error', message: `Het geüploade bestand is te groot.`})
    }

    if (file.type === 'video/mp4') {
      setImage(null)
      setCroppedImageUrl(null)
      setVideo(URL.createObjectURL(file))
      // setVideo(file)
    } else {
      setVideo(null)
      setImage(URL.createObjectURL(file))
      setCroppedImageUrl(URL.createObjectURL(file))
    }
    setCrop()
  }

  const uploadImage = async () => {
    let data;
    const formData = new FormData();
    formData.append("type", "rapporten");
    if (image) {
      const imageFile = await fetch(croppedImageUrl)
      .then((res) => res.blob())
      .then((blob) => new File([blob], "image.jpg", { type: "image/jpeg" }));
      formData.append("media", imageFile);
    } else if (video) {
      const videoFile = await fetch(video)
      .then((res) => res.blob())
      .then((blob) => new File([blob], "video.mp4", { type: "video/mp4" }));
      formData.append("media", videoFile);
    }


    await postRequest('/api/data/media', {
      body: formData
    }).then((response) => {data = response})
    if (!data.success) return; // Error!
    setUploadComplete(true)
    setFilePath(data.filename)
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear().toString();
    return `${day}-${month}-${year}`;
  };

  const saveReport = async () => {
    setRequestSend(false)

    if (requestSend) return null;
    setRequestSend(true)
    let data;
    const inputReslult = checkInput(bewoners, selectedBewoner, selectedBelofte, datum, verslag)
    if (inputReslult.success !== true) {setRequestSend(false); return notificationController.sendMessage({type: 'error', message: inputReslult.message})}

    const formData = new FormData();
    formData.append('bewonerID', selectedBewoner.bewonerID);
    formData.append('belofteID', parseInt(selectedBelofte));
    formData.append('datum', datum);
    formData.append('tekst', verslag);
    formData.append('path', filePath);

    await postRequest('/api/data/beleven', {
      body: formData
    }).then((response) => {data = response})
    if (!data.success && data.message === "FILE_TOO_LARGE") {setRequestSend(false); return  notificationController.sendMessage({type: 'error', message: `Het geüploade bestand is te groot.`})}
    if (!data.success) {setRequestSend(false); return  notificationController.sendMessage({type: 'error', message: `Er is iets fout gegaan bij het aanmaken van de rapportage. Probeer het opnieuw.`})}
    notificationController.sendMessage({type: 'success', message: `Aanmaken van rapportage voor ${selectedBewoner.naam} is gelukt.`})
    clearData('bekijken')
    readData('bekijken')
    pager.navigateTo('/bekijken')
  }

  return (
    <>
      <BackButton/>
      <Steps steps={[
        {title: 'Voor welke bewoner is deze rapportage?', content: ( handleSwipe ) => (
          <>
            <Search focus value={searchValue} setValue={setSearchValue} placeholder="Bewoner"/>
            {bewoners.success ? 
              (() => {
                const {bewonerList, _loadMore} = generateBewonersList(bewoners, searchValue, setSelectedBewoner, setSearchValue, limit, handleSwipe)
                loadMore = _loadMore
                return bewonerList
            })() : null}
            {loadMore && <Button styles={{fontSize: '.9rem', width: '100%'}} type="primary" title="Laad meer" callback={async () => {await fetchBewoners(); setLimit(limit + 10)}}/>}
          </>
        )}, 
        {title: 'Welke belofte hoort hier bij?', content: ( handleSwipe ) => ( 
          <>
            {generateBeloftes(handleSwipe)}
          </>
        )}, 
        {title: 'Klopt de datum & tijd?', content:       
          <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', height: '100%'}}>
            <DateInput type="datetime-local" value={datum} setValue={setDatum}/>
          </div>
        }, 
        {title: 'Schrijf het verslag.', content: 
          <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
            <Input charLimit={2000} hideLimit placeholder="Verslag" styles={{backgroundColor: '#e6e0ec', borderRadius: '4px', color: verslag.length >= 2000 ? 'var(--error-color)' : 'unset'}} rows={8} value={verslag} setValue={setVerslag}/>
          </div>
        }, 
        {title: 'Voeg een afbeelding of video toe.', swipe: false, content: ( handleSwipe ) => ( 
          <>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <div style={{width: image || video ? '93%' : '100%'}}>
                <Button styles={{fontSize: '.9rem', width: '100%'}} type="primary" title="Media bestand selecteren" callback={() => imageInputRef.current.click()}/>
              </div>

              {image || video ? <div style={{width: '5%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <DeleteIcon className={[beleven_logic.deleteIcon, beleven_style.deleteIcon].join(' ')} onClick={() => {setImage(); setVideo(); setCrop(); setCroppedImageUrl()}}/>
              </div> : null}
            </div>

            <div className={beleven_logic.imgWrapper}>
              {image ?
                <ReactCrop
                  crop={crop}
                  onChange={(crop, percentCrop) => {
                    setCrop(crop);
                    if (crop.width === 0 && crop.height === 0) {
                      setCroppedImageUrl(image);
                    }
                  }}
                  onComplete={async (crop) => {
                    const croppedImageUrl = await getCroppedImg(imageRef.current, crop);
                    if (crop.width === 0 && crop.height === 0) {
                      setCroppedImageUrl(image);
                    } else {
                      setCroppedImageUrl(croppedImageUrl);
                    }
                  }}
                >
                  <img ref={imageRef} src={image} alt="" />
                </ReactCrop>
              : 
              video ? <video style={{
                display: 'block',
                padding: '5px 0',
                width: '100%',
                height: video ? 'auto' : '200px',
                fontSize: '1rem',
                lineHeight: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }} src={video} controls></video>
              : null}
            </div>
            <input 
              ref={imageInputRef} 
              type="file" 
              onChange={onImageChange} 
              style={{display: 'none'}} 
              accept="image/png, image/jpg, image/jpeg, video/mp4" 
              capture="environment"
            />
            <Button styles={{fontSize: '.9rem', width: '100%', marginTop: '5px'}} type="primary" title="Opslaan" callback={() => {uploadImage(); handleSwipe('left')}}/>
          </>
        )}, 
        {title: 'Kloppen deze gegevens?', content: 
          <>
            <div style={{width: 'calc(90% - 50px)', margin: '25px auto'}}>
              <div>
                {video ?
                  <div><video className={[bekijken_logic.mediaContainer, bekijken_style.mediaContainer].join(' ')} src={video}/></div>
                : image ?
                  <div><img alt="" src={croppedImageUrl} className={[bekijken_logic.mediaContainer, bekijken_style.mediaContainer].join(' ')}></img></div>
                : <div className={[bekijken_logic.mediaContainer, bekijken_style.mediaContainer].join(' ')}>{selectedBewoner?.profielfoto && <img alt="" src={selectedBewoner?.profielfoto} className={[bekijken_logic.mediaContainer, bekijken_style.mediaContainer].join(' ')}></img>}</div>}

                <p className={[bekijken_logic.geboortedatum, bekijken_style.geboortedatum].join(" ")}>{selectedBewoner?.geboortedatum ? formatDate(selectedBewoner.geboortedatum) : 'xx-xx-xxxx'}</p>
              </div>

              <div className={bekijken_logic.textWrapper}>
                <p className={[bekijken_logic.activeReportText, bekijken_style.text].join(" ")}>{verslag}</p>
              </div>

              <div>
                <p className={[bekijken_logic.footerText, bekijken_style.text].join(" ")}><UserIcon style={{fill: '#e6e0ec'}}/>{selectedBewoner?.naam}</p>
                <p className={[bekijken_logic.footerText, bekijken_style.text].join(" ")}><BelovenIcon style={{fill: '#e6e0ec'}}/>{beloften.success ? beloften.values.find(item => item.belofteID === selectedBelofte)?.belofte : null}</p>
                {/* // Todo: Add this line and fix the medewerker name: <p className={[bekijken_logic.footerText, bekijken_style.text].join(" ")}><UserIcon style={{fill: '#e6e0ec'}}/>{cookies medewerker naam}</p> */}
              </div>
              <Button disabled={uploadComplete} styles={{fontSize: '.9rem', width: '100%'}} type="primary" title="Rapportage aanmaken" callback={() => saveReport()}/>
            </div>
          </>
        }, 
      ]}/>
    </>
  )
}

function generateBewonersList(bewoners, searchValue, setSelectedBewoner, setSearchValue, limit, handleSwipe) {
  // Only render 25 bewoners at a time
  // User can set a variable to change the starting index
  let _loadMore = false
  let index = 0
  if (!bewoners.success) return null;
  let bewonerList = bewoners.values.map((bewoner) => {
    if (index >= limit) {_loadMore = true; return null};
    // Check if the profilePicture exists if not retrive it from api
    const naam = bewoner.naam.toLowerCase();
    const lowerCaseSearchValue = searchValue.toLowerCase();
    if (!naam.includes(lowerCaseSearchValue)) return null;
    index++;
    return (
      <div key={bewoner.bewonerID} style={{display: "flex", alignItems: 'center', padding: '5px'}} onClick={() => {setSelectedBewoner(bewoner); setSearchValue(bewoner.naam); handleSwipe('left')}}>
        {bewoner.profielfoto && <img src={bewoner.profielfoto} style={{borderRadius: '50%', width: '50px', height: '50px', aspectRatio: '1/1', objectFit: 'cover'}} alt=""></img>}
        <p style={{color: 'var(--primary-text-color)', fontSize: '1.2rem', margin: '0 0 0 10px', textWrap: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{bewoner.naam}</p>
      </div>
    )
  })
  return {bewonerList, _loadMore}
}

function getCurrentDateTime() {
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, '0');
  const day = now.getDate().toString().padStart(2, '0');
  const hours = now.getHours().toString().padStart(2, '0');
  const minutes = now.getMinutes().toString().padStart(2, '0');

  const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
  return formattedDateTime;
}

function checkInput(bewoners, selectedBewoner, selectedBelofte, datum, verslag) {
  if (!selectedBewoner) return {success: false, message: 'Er is geen bewoner geselecteerd.'}
  if (!selectedBelofte) return {success: false, message: 'Er is geen belofte geselecteerd.'}
  if (bewoners.values.find(bewoner => parseInt(bewoner.bewonerID) === parseInt(selectedBewoner.bewonerID)) === undefined) return {success: false, message: 'Geselecteerde bewoner bestaat niet.'}
  if (!datum) return {success: false, message: 'Er is geen datum ingevoerd.'}
  if (!verslag) return {success: false, message: 'Er is geen verslag ingevoerd.'}
  return {success: true};
}

const getCroppedImg = (image, crop) => {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      if (!blob) {
        console.error('Canvas is empty');
        return;
      }
      blob.name = 'cropped.jpg';
      const fileUrl = window.URL.createObjectURL(blob);
      resolve(fileUrl);
    }, 'image/jpeg', 1);
  });
}

function getIcon(nummer) {
  switch (nummer) {
    case 1:
      return belofte1;
    case 2:
      return belofte2;
    case 3:
      return belofte3;
    case 4:
      return belofte4;
    case 5:
      return belofte5;
    case 6:
      return belofte6;
    case 7:
      return belofte7;
    case 8:
      return belofte8;
    case 9:
      return belofte9;
    case 10:
      return belofte10;
    default:
      return '';
  }
}