import { HashRouter } from "react-router-dom";
import { PagerProvider, NotificationController } from "./components/";
import { DataStorage } from "./logic/dataStorage";
import React from "react";

export default function App() {

  return (
    <HashRouter>
      <DataStorage>
          <NotificationController>
            <PagerProvider />
          </NotificationController>
      </DataStorage>
    </HashRouter>
  );
}
