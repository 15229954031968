import { useContext, useEffect, useState } from "react";
import { Button, Header, Input, PagerContext, NotificationContext, Delete, Steps } from "../../../components";
import { DataStorageContext, putRequest } from "../../../logic";
import { beheer_logic } from "../../../styles";
export default function LocatieEditor() {
  const pager = useContext(PagerContext)
  const { readData, clearData } = useContext(DataStorageContext)
  const notificationController = useContext(NotificationContext)

  const [requestSend, setRequestSend] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [data, setData] = useState()

  useEffect(() => {
    const fetchData = async () => {
      await pager.allowed_on_page()
      await createDataObject()
    };
    fetchData();
    // eslint-disable-next-line
  }, [pager.arguments]);

  const createDataObject = async () => {
    const _values = Object.values((await readData('beheer_locaties')).values).find((item) => String(item.locatieID) === pager.getArgument('locatie')) || {};
    if (Object.keys(_values).length === 0) return pager.navigateTo('/beheer/applicatie/locatie');

    setData(Object.keys(_values).reduce((acc, key) => {
      acc[key] = _values[key];
      return acc;
    }, {}));
  }

  const save = async () => {
    let result;
    setRequestSend(true)
    const checkResult = checkInput(data)
    if (!checkResult.success) {setRequestSend(false); return notificationController.sendMessage({type: 'error', message: checkResult.message})}
    await putRequest('/api/data/locatie', {
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        organisatieID: data?.organisatieID,
        naam: data?.naam,
        contactpersoon: data?.contactpersoon,
        email: data?.email,
        telefoon: data?.telefoon,
        adres: data?.adres,
        huisnummer: data?.huisnummer,
        postcode: data?.postcode,
        plaats: data?.plaats,
        opmerkingen: data?.opmerkingen,
        locatieID: data?.locatieID
      })
    }).then((response) => {result = response})
    if (!result.success && data.message === "INVALID_ADDRESS") {setRequestSend(false); return notificationController.sendMessage({type: 'error', message: 'Het adres is niet gevonden. Controleer of het adres correct is ingevuld.'})}
    if (!result.success) {setRequestSend(false); return notificationController.sendMessage({type: 'error', message: 'Er is iets fout gegaan bij het opslaan van de locatie. Probeer het opnieuw.'})}
    clearData('beheer_locaties')
    readData('beheer_locaties')
    notificationController.sendMessage({type: 'success', message: 'De locatie is bewerkt en opgeslagen.'})
    setEditMode(false)
  }

	return (
    <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%'}}>

      <div>
        <Header 
          title={`Locatie: ${data?.naam}`}
          backUrl="/beheer/applicatie/locatie"
          playAnimation={false}
        />

        {!editMode && <div>{generateOverview(data)}</div>}
      </div>

      {!editMode && <div style={{display: 'flex', justifyContent: 'space-between', paddingBottom: '10px'}}>
        <Button type="primary" title="Bewerken" callback={() => setEditMode(true)} styles={{width: '48%'}}/>
        <Delete title={`Locatie: ${data?.naam}`} endpoint="/api/data/locatie" id={data?.locatieID} storageName="beheer_locaties" buttonStyles={{width: '48%'}} callback={() => pager.navigateTo('/beheer/applicatie/locatie')}/>
      </div>}

      {editMode && <Steps
        steps={[
          {
            title: "Wat is de naam van de locatie?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <Input required title="Naam" name="naam" value={data?.naam} setValue={setData} placeholder="Naam van de locatie"/>
                <Button type="primary" title="Bewerken Annuleren" callback={() => {setEditMode(false); createDataObject()}} styles={{width: '100%'}}/>
              </div>
            )
          },
          {
            title: "Wat is de naam van de eerste contact persoon?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <Input required title="Contactpersoon" name="contactpersoon" value={data?.contactpersoon} setValue={setData} placeholder="Naam van de contactpersoon"/>
                <Button type="primary" title="Bewerken Annuleren" callback={() => {setEditMode(false); createDataObject()}} styles={{width: '100%'}}/>
              </div>
            )
          },
          {
            title: "Wat is het email-adres van de eerste contact persoon?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <Input required title="E-mail" name="email" value={data?.email} setValue={setData} placeholder="Email van de contactpersoon"/>
                <Button type="primary" title="Bewerken Annuleren" callback={() => {setEditMode(false); createDataObject()}} styles={{width: '100%'}}/>
              </div>
            )
          },
          {
            title: "Wat is het telefoonnummer van de eerste contact persoon?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <Input required title="Telefoon" name="telefoon" value={data?.telefoon} setValue={setData} placeholder="Telefoonnummer van de contactpersoon"/>
                <Button type="primary" title="Bewerken Annuleren" callback={() => {setEditMode(false); createDataObject()}} styles={{width: '100%'}}/>
              </div>
            )
          },
          {
            title: "Wat is het adres van de locatie?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <div>
                  <Input required title="Straat" name="adres" value={data?.adres} setValue={setData} placeholder="Straat"/>
                  <Input required title="Huisnummer" name="huisnummer" value={data?.huisnummer} setValue={setData} placeholder="Huisnummer"/>
                </div>
              <Button type="primary" title="Bewerken Annuleren" callback={() => {setEditMode(false); createDataObject()}} styles={{width: '100%'}}/>
              </div>
            )
          },
          {
            title: "Wat is de postcode van de locatie?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <div>
                  <Input required title="Postcode" name="postcode" value={data?.postcode} setValue={setData} placeholder="Postcode van de locatie"/>
                  <Input required title="Plaats" name="plaats" value={data?.plaats} setValue={setData} placeholder="Plaats van de organisatie"/>
                </div>
                <Button type="primary" title="Bewerken Annuleren" callback={() => {setEditMode(false); createDataObject()}} styles={{width: '100%'}}/>
              </div>
            )
          },
          {
            title: "Zijn er nog opmerkingen over de locatie?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <Input title="Opmerkingen" rows={5} name="opmerkingen" value={data?.opmerkingen} setValue={setData} placeholder="Opmerkingen over de locatie"/>
                <Button type="primary" title="Bewerken Annuleren" callback={() => {setEditMode(false); createDataObject()}} styles={{width: '100%'}}/>
              </div>
            )
          },
          {
            title: "Kloppen deze gegevens?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <div>{generateOverview(data)}</div>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Button type="primary" title="Nee, annuleren" callback={() => setEditMode(false)} styles={{width: '45%'}}/>
                  <Button disabled={requestSend} type="primary" title="Ja, opslaan" callback={() => save()} styles={{width: '45%'}}/>
                </div>
              </div>
            )
          },
        ]}
      />
      }
		</div>
	);
}

function generateOverview(data) {
  if (!data) return null
  const filter = ["organisatieID", "locatieID", "latitude", "longitude", "isActief"]

  return Object.keys(data).map(item => {
    if (filter.includes(item)) return null
    if (typeof data[item] === 'boolean') data[item] = data[item] ? "Ja" : "Nee"

    return (
      <div className={beheer_logic.recordContainer} key={item} style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'var(--primary-text-color)'}}>
        <p className={beheer_logic.recordText}>{item}</p>
        <p className={beheer_logic.recordText} style={{textTransform: 'unset'}}>{data[item]}</p>
      </div>
    )
  })
}

function checkInput(data) {
  if (!data?.naam) return { success: false, message: 'Voer een locatie naam in.'}
  if (!data?.contactpersoon) return { success: false, message: 'Voer een contactpersoon in.'}
  if (!data?.email) return { success: false, message: 'Voer een emailadres in.'}
  if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(data?.email)) return { success: false, message: 'Voer een geldig emailadres in. (voorbeeld@gmail.com)'}
  if (!data?.telefoon) return { success: false, message: 'Voer een telefoonnummer in.'}
  if (!data?.adres) return { success: false, message: 'Voer een adres in.'}
  if (!data?.huisnummer) return { success: false, message: 'Voer een huisnummer in.'}
  if (!data?.postcode) return { success: false, message: 'Voer een postcode in.'}
  if (!/^[0-9]{4}[A-Z]{2}$/.test(data?.postcode)) return { success: false, message: 'Voer een geldige postcode in. (1234AB)'}
  if (!data?.plaats) return { success: false, message: 'Voer een plaats in.'}
  return {success: true}
}