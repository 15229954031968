import React, { useEffect, useState } from "react";
import { input_logic, input_style } from "../../../styles";
import { RequiredIcon, SearchIcon } from "../../../Images";

/**
 * Input component.
 * @param {string} props.title - The title of the input component.
 * @param {Array} props.searchValues - The values to search through. - { keywords: [], displayValue: '", value: '' }
 * @param {number} props.showItems - The amount of items to show in the dropdown.
 * @param {string} props.placeholder - The placeholder text for the input component.
 * @param {string} props.value - The value of the input component.
 * @param {function} props.setValue - The function to set the value of the input component.
 * @param {string} props.name - The name of the input component.
 * @param {Object} props.styles - The styles to apply to the input component.
 * @param {number} props.tabIndex - The tab index of the input component.
 * @param {boolean} props.disabled - Indicates if the input is disabled.
 * @param {boolean} props.required - Indicates if the input is required.
 * @param {boolean} props.focus - Indicates if the input should be focused.
 * @returns {JSX.Element} The rendered input component.
 */
export default function Search({ title, searchValues, showItems, disabled, placeholder, name, value, setValue, styles, tabIndex, required, focus}) {
    const [inputActive, setInputActive] = useState(false);
    const inputRef = React.useRef(null);

    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (inputRef.current && !inputRef.current.contains(event.target) && event.target.id !== "dropdownRow") {
          setInputActive(false);
        }
      };
      document.addEventListener('mousedown', handleOutsideClick);
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }, []);
  
    let element_styles = {};
    if (styles || typeof styles === "object") element_styles = styles
  
    showItems = showItems === undefined ? 5 : showItems

    const onValueChange = (e) => {
      const value = e.target.value
      if (name) {
          setValue(prev => ({
            ...prev,
            [name]: value
          }))
      } else setValue(value)
    }

    useEffect(() => {
      if (focus) inputRef.current.focus()
      // eslint-disable-next-line
    }, [])  
  
    return (
      <div className={disabled ? input_logic.disabled : ''}>
        <label className={[input_logic.text, input_style.text].join(" ")}>
          <span>{title}</span> 
          {required && <RequiredIcon className={[input_style.required, input_logic.required].join(" ")}/>}
        </label>

        <div className={[input_logic.inputFieldContainer, input_style.inputFieldContainer].join(" ")}>
          <div className={[input_logic.searchIcon, input_style.searchIcon].join(" ")} onClick={() => inputRef.current.focus()}><SearchIcon/></div>
          <input 
            disabled={disabled}
            tabIndex={tabIndex}
            onFocus={() => setInputActive(true)}
            className={[input_logic.inputField, input_style.inputField].join(" ")} 
            placeholder={placeholder} 
            value={value} 
            onChange={onValueChange} 
            style={element_styles}
            ref={inputRef}
          ></input>
        </div>
        {Array.isArray(searchValues) && !disabled && inputActive && renderDropdown(searchValues, value, showItems, onValueChange, setInputActive)}
      </div>
    )
  }


function renderDropdown(searchValues, value, showItems, onValueChange, setInputActive) {
  return (
    <div className={[input_logic.searchDropdown, input_style.searchDropdown].join(" ")}>
      {searchValues
        .filter(item =>
          (item.keywords.some(keyword =>
            keyword.toLowerCase().startsWith(value.toLowerCase())
          ) ||
          item.displayName.toLowerCase().startsWith(value.toLowerCase()))
        )
        .slice(0, showItems)
        .map((item, index) => (
          <div
            // key={item.displayName}
            key={`${item.displayName}-${index}`}

            id="dropdownRow"
            onClick={() => {
              onValueChange({target: {value: item.value}})
              setInputActive(false);
            }}
            className={[input_logic.searchDropdownRow, input_style.searchDropdownRow].join(" ")}
          >
            {item.displayName || null}
          </div>
          )
        )
      }
    </div>
  )
}