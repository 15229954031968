import { input_logic, input_style } from "../../../styles";
import { RequiredIcon } from "../../../Images";
import React from "react";
/**
 * Dropdown component.
 * @param {Object} props - The props for the dropdown component.
 * @param {string} props.title - The title of the dropdown component.
 * @param {Object} props.styles - The styles to apply to the dropdown component.
 * @param {number} props.tabIndex - The tab index of the dropdown component.
 * @param {string} props.placeholder - The placeholder text for the dropdown component.
 * @param {Array} props.dropdownValues - The array of dropdown values [{name: STRING, value: STRING}].
 * @param {string} props.value - The selected value of the dropdown component.
 * @param {function} props.setValue - The function to set the selected value of the dropdown component.
 * @param {boolean} props.required - Indicates if the dropdown is required.
 * @param {boolean} props.disabled - Indicates if the dropdown is disabled.
 * @param {string} props.name - The name of the dropdown component.
 * @param {boolean} props.focus - Indicates if the dropdown should be focused.
 * @returns {JSX.Element} The rendered dropdown component.
 */
export default function Dropdown({ title, styles, tabIndex, placeholder, dropdownValues, value, setValue, required, disabled, name, focus }) {
  let element_styles = {};
  if (styles || typeof styles === "object") element_styles = styles

  if (dropdownValues === undefined || !(Array.isArray(dropdownValues))) throw new Error("dropdownValues must be an array. /src/components/assets/elements/dropdown.js")
  
  const onValueChange = (e) => {
    const value = e.target.value
    if (name) {
      setValue(prev => ({
        ...prev,
        [name]: value
      }))
    } else setValue(value)
  }

  React.useEffect(() => {
    if (focus) inputField.current.focus()
    // eslint-disable-next-line
  }, [])

  const inputField = React.useRef(null)

  
  return (
    <div className={disabled ? input_logic.disabled : ''}>
      <label className={[input_logic.text, input_style.text].join(" ")}>
        <span>{title}</span> 
        {required && <RequiredIcon className={[input_style.required, input_logic.required].join(" ")}/>}
      </label>
      <select 
        ref={inputField}
        disabled={disabled}
        tabIndex={tabIndex} 
        style={element_styles} 
        className={[
          input_logic.dropdownField, 
          input_style.dropdownField,
        ].join(" ")} 
        placeholder={placeholder} 
        value={value} 
        onChange={onValueChange}>
        {
          dropdownValues.map((item) => {
            return <option key={item.value} value={item.value}>
              {item.name}
            </option>
          })
        }
      </select>
    </div>
  )
}
