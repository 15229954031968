import { input_logic, input_style } from "../../../styles";
import { RequiredIcon, EyeIcon, EyeSlashIcon } from "../../../Images";
import React, { useState } from "react";

/**
 * Password component.
 * @param {string} props.title - The title of the input component.
 * @param {string} props.placeholder - The placeholder text for the input component.
 * @param {string} props.value - The value of the input component.
 * @param {function} props.setValue - The function to set the value of the input component.
 * @param {string} props.name - The name of the input component.
 * @param {Object} props.styles - The styles to apply to the input component.
 * @param {number} props.tabIndex - The tab index of the input component.
 * @param {boolean} props.disabled - Indicates if the input is disabled.
 * @param {boolean} props.required - Indicates if the input is required.
 * @param {boolean} props.focus - Indicates if the input should be focused.
 * @returns {JSX.Element} The rendered input component.
 */
export default function Password({ title, styles, tabIndex, placeholder, value, setValue, disabled, required, name, focus }) {
  const [viewPassword, setViewPassword] = useState(false);

  let element_styles = {};
  if (styles || typeof styles === "object") element_styles = styles

  const onValueChange = (e) => {
    const value = e.target.value
    if (name) {
        setValue(prev => ({
          ...prev,
          [name]: value
        }))
    } else setValue(value)
  }

  React.useEffect(() => {
    if (focus) inputField.current.focus()
    // eslint-disable-next-line
  }, [])

  const inputField = React.useRef(null)


  return (
    <div className={disabled ? input_logic.disabled : ''}>

      <header className={input_logic.headerWrapper}>
        <p className={[input_logic.text, input_style.text].join(" ")}>{title}</p>
        {required && <RequiredIcon className={[input_style.required, input_logic.required].join(" ")} />}
      </header>

      <div className={[input_logic.inputFieldContainer, input_style.inputFieldContainer].join(" ")}>
        <input ref={inputField} disabled={disabled} style={element_styles} tabIndex={tabIndex} className={[input_logic.inputField, input_style.inputField].join(" ")} type={viewPassword ? 'text' : 'password'} placeholder={placeholder} value={value} onChange={onValueChange}></input>
        <div className={[input_logic.viewPassword, input_style.viewPassword].join(" ")} onClick={() => !disabled ? setViewPassword(!viewPassword) : undefined}>{viewPassword ? <EyeSlashIcon/> : <EyeIcon/>}</div>
      </div>

    </div>
  )
}
