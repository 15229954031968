import React from "react";
import { background_logic, background_style } from "../../styles";

export default function Background({ zIndex }) {
  return (
    <div className={background_logic.wrapper}>
      <div style={zIndex ? {zIndex: zIndex, position: 'absolute'} : null} className={[background_logic.background, background_style.background].join(' ')}></div>
      <div style={zIndex ? {zIndex: zIndex, position: 'absolute'} : null} className={[background_logic.halfCircle, background_style.halfCircle].join(' ')}></div>
    </div>
  )
}