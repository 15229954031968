import React, { useState, useEffect, useContext } from 'react';
import { BackButton, Header, PagerContext } from "../components";

export default function Privacy() {

  useEffect(() => {
    
  }, [])

  return (
    <>
      <Header title='Privacy verklaring' backUrl='/' playAnimation={false}/>
      <div style={{color: 'var(--primary-text-color)'}}>
        <p>Kwaliteit in Huis, gevestigd aan Galvanistraat 7, 6716 AE Ede, is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.<br/><br/>Contactgegevens:<br/><br/>https://familieapp.kwaliteitinhuis.nl<br/><br/>Galvanistraat 7, 6716 AE Ede<br/><br/>+310611130504<br/><br/>Daan de Haan is de Functionaris Gegevensbescherming van Kwaliteit in Huis Hij/zij is te bereiken via daan@daan-helpt.nl</p>
        
        <h3>Persoonsgegevens die wij verwerken</h3>
        <p>Kwaliteit in Huis verwerkt uw persoonsgegevens doordat u gebruik maakt van onze diensten en/of omdat u deze zelf aan ons verstrekt. Hieronder vindt u een overzicht van de persoonsgegevens die wij verwerken:<br/><br/>- Voor- en achternaam<br/>- E-mailadres</p>
      
        <h3>Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</h3>
        <p>Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn bij de online activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming. Als u er van overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via daan@daan-helpt.nl, dan verwijderen wij deze informatie.</p>

        <h3>Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken</h3>
        <p>Kwaliteit in Huis verwerkt uw persoonsgegevens voor de volgende doelen:<br/><br/>- U de mogelijkheid te bieden een account aan te maken</p>

        <h3>Hoe lang we persoonsgegevens bewaren</h3>
        <p>Kwaliteit in Huis bewaart uw persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor uw gegevens worden verzameld. Wij hanteren de volgende bewaartermijnen voor de volgende (categorieën) van persoonsgegevens:<br/><br/>- Afbeeldingen & video's: 30 dagen<br/>- Persoonsgegevens: 7 jaar</p>

        <h3>Delen van persoonsgegevens met derden</h3>
        <p>Kwaliteit in Huis verkoopt uw gegevens niet aan derden en verstrekt deze uitsluitend indien dit nodig is voor de uitvoering van onze overeenkomst met u of om te voldoen aan een wettelijke verplichting. Met bedrijven die uw gegevens verwerken in onze opdracht, sluiten wij een bewerkersovereenkomst om te zorgen voor eenzelfde niveau van beveiliging en vertrouwelijkheid van uw gegevens. Kwaliteit in Huis blijft verantwoordelijk voor deze verwerkingen.</p>
      
        <h3>Cookies, of vergelijkbare technieken, die wij gebruiken</h3>
        <p>Kwaliteit in Huis gebruikt alleen technische en functionele cookies. En analytische cookies die geen inbreuk maken op uw privacy. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen op uw computer, tablet of smartphone. De cookies die wij gebruiken zijn noodzakelijk voor de technische werking van de website en uw gebruiksgemak. Ze zorgen ervoor dat de website naar behoren werkt en onthouden bijvoorbeeld uw voorkeursinstellingen. Ook kunnen wij hiermee onze website optimaliseren. U kunt zich afmelden voor cookies door uw internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kunt u ook alle informatie die eerder is opgeslagen via de instellingen van uw browser verwijderen.</p>

        <h3>Gegevens inzien, aanpassen of verwijderen</h3>
        <p>U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heeft u het recht om uw eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van uw persoonsgegevens door Kwaliteit in Huis en heeft u het recht op gegevensoverdraagbaarheid. Dat betekent dat u bij ons een verzoek kunt indienen om de persoonsgegevens die wij van u beschikken in een computerbestand naar u of een ander, door u genoemde organisatie, te sturen. U kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van uw persoonsgegevens of verzoek tot intrekking van uw toestemming of bezwaar op de verwerking van uw persoonsgegevens sturen naar daan@daan-helpt.nl. Om er zeker van te zijn dat het verzoek tot inzage door u is gedaan, vragen wij u een kopie van uw identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie uw pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van uw privacy. We reageren zo snel mogelijk, maar binnen vier weken, op uw verzoek. Kwaliteit in Huis wil u er tevens op wijzen dat u de mogelijkheid heeft om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link: https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons</p>
      
        <h3>Hoe wij persoonsgegevens beveiligen</h3>
        <p>Kwaliteit in Huis neemt de bescherming van uw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als u de indruk heeft dat uw gegevens niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op met onze klantenservice of via daan@daan-helpt.nl</p>
      </div>
    </>
  );
}
