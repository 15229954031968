import React, { useEffect, useState, useCallback } from 'react';
import { useSwipe } from '../';
import { steps_logic, steps_style } from '../../styles';
import { SmallArrow } from '../../Images';

// Note: Max length of steps is 20.

// Todo: Add a info button to show which step is the user on and provide information about the question.

/* Example of steps prop:
 * steps = [
 *   {title: 'Kies een bewoner', swipe: false, content: 
 *     <div>
 *       <p>Bewoner Kiezen (01)</p>
 *     </div>}, 
 *   {title: 'Schrijf een rapportage', swipe: true, content: <div><textarea></textarea></div>}, 
 *   {title: 'Upload Media', content: <div><textarea></textarea></div>}, 
 * ]
 * 
 * Additional info: You can also pass a function as content. This function will be called with the handleSwipe function as argument.
 * Example: content: ( handleSwipe ) => ( <div></div> )
 * Leaving `Swipe` unset will default to true.
*/ 
export default function Steps({ steps: _steps }) {

    if (!_steps) throw new Error('Steps is a required prop.');

    const [index, setIndex] = useState(0);
    const [content, setContent] = useState([0]);
    const [steps, setSteps] = useState(_steps);

    useEffect(() => {
        setSteps(_steps.filter((step) => step));
    }, [_steps])

    const handleSwipe = (direction, _index) => {
        if ((direction === 'left' && index < steps.length - 1) || (direction === 'right' && index > 0) || (direction === 'click' && _index !== index)) {
            const newIndex = direction === 'left' ? index + 1 : direction === 'right' ? index - 1 : _index;
            const prevIndex = index;

            const wrapper = document.getElementById(`wrapper`);
            wrapper.classList.add(steps_logic.fade_out);

            wrapper.addEventListener('animationend', () => {
                let _content = [...content, newIndex];
                _content = _content.filter((i) => i !== prevIndex);
                setContent(_content);
                setIndex(newIndex);
                wrapper.classList.remove(steps_logic.fade_out);
                wrapper.classList.add(steps_logic.fade_in);
            });
        }
    };

    const swipeHandlers = useSwipe({
        onSwipedLeft: () => {
            handleSwipe('left');
        },
        onSwipedRight: () => {
            handleSwipe('right');
        }
    });

    return (
        <div style={{ width: '90%', display: 'block', margin: '0 auto', height: 'calc(100% - 60px)' }}>
            <div id="wrapper" style={{ display: 'flex', flexDirection: 'column', height: '93%'}}>
                {steps[index]?.title && <p {...swipeHandlers} className={[steps_logic.title, steps_style.title].join(' ')}>{steps[index]?.title}</p>}
                {content.map((item) => {
                    const handlers = steps[item]?.swipe == true || steps[item]?.swipe === undefined ? { ...swipeHandlers } : {};
                    return (
                        <div
                            {...handlers}
                            id={`content-${item}`}
                            key={item}
                            className={[steps_logic.content, steps_logic.fade_in].join(' ')}
                        >
                            {typeof steps[item]?.content === 'function' ? steps[item]?.content(handleSwipe) : steps[item]?.content}
                        </div>
                    );
                })}
            </div>
            {stepsRenderer(steps, index, handleSwipe, swipeHandlers)}
        </div>
    );
}

// Always render dots.
// Add the dots in a 90% div with no overflow.
// Make sure that the arrow are always visible.

function stepsRenderer(steps, currentIndex, handleSwipe, swipeHandlers) {
    return (
        <footer {...swipeHandlers} className={steps_logic.footer}>
            <div style={{width: '5%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><SmallArrow className={[steps_logic.arrowLeft, steps_style.arrow].join(' ')} onClick={() => handleSwipe('right')} /></div>
            <div className={[steps_logic.stepIndicatorWrapper, steps.length > 10 ? steps_logic.hasDots : steps_logic.hasLines].join(' ')}>
                {steps.map((step, index) => {
                    return <div key={steps.indexOf(step)} className={[steps_logic.stepIndicator, steps_logic.dot, steps_style.dot, currentIndex === index ? steps_style.active : steps_style.stepIndicator].join(' ')} onClick={() => handleSwipe('click', index)}></div>
                })}
            </div>
            <div style={{width: '5%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><SmallArrow className={[steps_logic.arrowRight, steps_style.arrow].join(' ')} onClick={() => handleSwipe('left')} /></div>
        </footer>
    )
}