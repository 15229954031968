import { useContext, useEffect, useState } from "react";
import { BackButton, PagerContext, Popup, TegelList } from "../components";
import { DataStorageContext, fetchMedia } from "../logic";
import { beloven_logic, beloven_style } from "../styles";
import { belofte1, belofte2, belofte3, belofte4, belofte5, belofte6, belofte7, belofte8, belofte9, belofte10, XmarkIcon } from "../Images";
export default function Beloven() {
  const pager = useContext(PagerContext)
  const { readData, saveData } = useContext(DataStorageContext)
  const [beloftes, setBeloftes] = useState({})
  const [activeBelofte, setActiveBelofte] = useState({})
  const [popupActive, setPopupActive] = useState(false)

  useEffect(() => {

    const fetchData = async () => {
      await pager.allowed_on_page()
      let _beloftes = await readData('beloftes')
      setBeloftes(_beloftes)
      _beloftes = await Promise.all(_beloftes.values.map(async (belofte) => {
        if (typeof belofte.afbeelding === 'string' && !belofte.afbeelding.startsWith('blob')) {
          const data = await fetchMedia(`/api/data/media?${new URLSearchParams({id: belofte.afbeelding})}`, {})
          if (data.status === 500) { belofte.afbeelding = null; return belofte}
          const blob = await data.blob()
          belofte.afbeelding = URL.createObjectURL(blob)
        }
        return belofte
      }))

      setBeloftes({ values: _beloftes })
      saveData('beloftes', _beloftes )
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const render = () => {
    if (!beloftes.values) return null;
    return Object.values(beloftes.values).map((belofte) => {
      return {
        name: "",
        callback: () => {
          setActiveBelofte(belofte);
          setPopupActive(true);
        },
        icon: getIcon(belofte.nummer)
      }
    });
  };

  return (
    <>
      <BackButton type="back_button"/>
      <TegelList data={render()}/>
      {popupActive && 
        <div className={beloven_logic.popupContainer}>
          <Popup toggle={setPopupActive} style={{maxWidth: '80%', backgroundColor: 'rgba(29, 29, 31, 0.9)', border: 'none'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '15px'}}>
              <p style={{margin: '0', color: "white", fontWeight: '700', fontSize: '.9rem', textAlign: 'center', marginLeft: '15px'}}>{activeBelofte.belofte}:</p>
              <button className={[beloven_logic.closeButton, beloven_style.closeButton].join(' ')} onClick={() => setPopupActive(false)}><XmarkIcon/></button>
            </div>
            {activeBelofte.subbeloftes && activeBelofte.subbeloftes.map((subBelofte, index) => {
              return <p key={index} style={{margin: '2px 0',color: "white", fontSize: '.8rem', marginLeft: '30px'}}>{subBelofte.nummer}. {subBelofte.subBelofte}</p>
            })}
            <img src={activeBelofte.afbeelding} alt="" className={beloven_logic.image}/>
          </Popup>
        </div>}
    </>
  );
}

function getIcon(nummer) {
  switch (nummer) {
    case 1:
      return belofte1;
    case 2:
      return belofte2;
    case 3:
      return belofte3;
    case 4:
      return belofte4;
    case 5:
      return belofte5;
    case 6:
      return belofte6;
    case 7:
      return belofte7;
    case 8:
      return belofte8;
    case 9:
      return belofte9;
    case 10:
      return belofte10;
    default:
      return '';
  }
}