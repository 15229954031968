import { useContext, useEffect, useState } from "react";
import { PagerContext, ResultList, Header, ListButton, Search } from "../../components";
import { DataStorageContext } from "../../logic";
export default function Beheer() {
  const pager = useContext(PagerContext)
  const { readData } = useContext(DataStorageContext)
  const [permission, setPermission] = useState({})
  const [searchValue, setSearchValue] = useState('') 
  const [locaties, setLocaties] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      await pager.allowed_on_page()
      setPermission(await readData('permission'))
      setLocaties(await readData('beheer_locaties'))
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header 
        title="Locaties"
        searchbar={{
          value: searchValue,
          setValue: setSearchValue
        }}
      />

      <div style={{width: 'calc(100% - 20px)', padding: '10px'}}>
        <Search title="Zoeken" placeholder="Typ om te zoeken" value={searchValue} setValue={setSearchValue}/>

        {permission.values && [1,2].includes(permission.values.rechtengroep) && (
          <ListButton title="Applicatie beheer" callback={() => pager.navigateTo(`/beheer/applicatie`)}/>
        )}

        <ResultList 
          data={locaties.values} 
          url={(locatie) => {return `/beheer/locatie?locatie=${locatie.locatieID}`}} 
          title={(item) => item.naam}
          filter={(locaties) => {
            const searchParts = searchValue.toLowerCase().split(' ').filter(part => part.trim() !== '');
            return searchParts.length === 0 ||
              searchParts.every(part =>
                String(locaties.locatieID).toLowerCase().includes(part) ||
                locaties.naam.toLowerCase().includes(part) ||
                locaties.adres.toLowerCase().includes(part) ||
                locaties.huisnummer.toLowerCase().includes(part) ||
                locaties.postcode.toLowerCase().includes(part) ||
                locaties.plaats.toLowerCase().includes(part) ||
                locaties.contactpersoon.toLowerCase().includes(part)
              );
            }}
        />
      </div>


    </>
  );
}
