import { useContext, useEffect, useState } from "react";
import { Button, Input, PagerContext, Header, Search, Steps, ListButton, NotificationContext } from "../../../components";
import { DataStorageContext, postRequest } from "../../../logic";
import { beheer_logic } from "../../../styles";
export default function LocatieNieuw() {
  const pager = useContext(PagerContext)
  const { readData, clearData} = useContext(DataStorageContext)
  const notificationController = useContext(NotificationContext)
  const [organisaties, setOrganisaties] = useState({})
  const [organisatie, setOrganisatie] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [requestSend, setRequestSend] = useState(false)

  const [data, setData] = useState({
    organisatieID: '',
    naam: '',
    organisatie: '',
    contactpersoon: '',
    email: '',
    telefoon: '',
    adres: '',
    huisnummer: '',
    postcode: '',
    plaats: '',
    opmerkingen: '',
  })

  useEffect(() => {
    const fetchData = async () => {
      await pager.allowed_on_page()
      const _organisaties = await readData('beheer_organisaties')
      setOrganisaties(_organisaties)
    };
    fetchData();
    // eslint-disable-next-line
  }, [pager.arguments]);
  // eslint-disable-next-line

  const save = async () => {
    setRequestSend(true)
    let result;
    const checkResult = checkInput(data, organisaties)
    if (!checkResult.success) {setRequestSend(false); return notificationController.sendMessage({type: 'error', message: checkResult.message})}
    await postRequest('/api/data/locatie', {
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        organisatieID: data?.organisatieID,
        naam: data?.naam,
        contactpersoon: data?.contactpersoon,
        email: data?.email,
        telefoon: data?.telefoon,
        adres: data?.adres,
        huisnummer: data?.huisnummer,
        postcode: data?.postcode,
        plaats: data?.plaats,
        opmerkingen: data?.opmerkingen,
      })
    }).then((response) => {result = response})
    if (!result.success && data.message === "INVALID_ADDRESS") {setRequestSend(false); return notificationController.sendMessage({type: 'error', message: "Het adres is niet gevonden. Controleer of het adres correct is ingevuld."})}
    if (!result.success) {setRequestSend(false); return notificationController.sendMessage({type: 'error', message: 'Er is iets fout gegaan tijdens het aanmaken van de locatie. Probeer het opnieuw.'})}
    clearData('beheer_locaties')
    readData('beheer_locaties')
    notificationController.sendMessage({type: 'success', message: 'Locatie is succesvol aangemaakt.'})
    pager.navigateTo('/beheer/applicatie/locatie')
  }

  const generateOrganisaties = (searchValue, setSearchValue, handleSwipe) => {
    if (!organisaties.success || organisatie?.values === null) return null;

    return organisaties.values.map((organisatie) => {
      const naam = organisatie.naam.toLowerCase();
      const lowerCaseSearchValue = searchValue.toLowerCase();
      if (!naam.includes(lowerCaseSearchValue)) return null;

      return (
        <div key={organisatie.organisatieID} style={{display: "flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '10px 0'}} onClick={(e) => { 
          setData(prev => ({
              ...prev,
              organisatieID: organisatie.organisatieID,
              organisatie: organisatie.naam
          }))
          
          setSearchValue(organisatie.naam); 
          handleSwipe('left')
        }}>
          <div style={{width: '70%'}}>
            <p style={{color: 'var(--primary-text-color)', fontSize: '1.2rem', width: '100%', margin: '0', textWrap: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{organisatie.naam}</p>
            <p style={{color: 'var(--primary-text-color)', fontSize: '1.2rem', width: '100%', margin: '0', textWrap: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{organisatie.adres} {organisatie.huisnummer}, {organisatie.plaats}</p>
          </div>
          <Button type="primary" title="Kiezen"/>
        </div>
      )

    })
  }

	return (
		<>
      <Header 
        title={`Locatie: ${data?.naam}`}
        backUrl="/beheer/applicatie/locatie"
        playAnimation={false}
      />

      <Steps
        steps={[
          {
            title: "Wat is de naam van de locatie?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <Input required title="Naam" name="naam" value={data?.naam} setValue={setData} placeholder="Naam van de locatie"/>
              </div>
            )
          },
          {
            title: "Onder welke organisatie valt deze locatie?",
            content: (handleSwipe) => (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'start', height: '100%'}}>
                <Search required title="Organisatie" value={searchValue} setValue={setSearchValue} placeholder="Typ om te zoeken..."/>
                <div>{generateOrganisaties(searchValue, setSearchValue, handleSwipe)}</div>
              </div>
            )
          },
          {
            title: "Wat is de naam van de eerste contact persoon?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <Input required title="Contactpersoon" name="contactpersoon" value={data?.contactpersoon} setValue={setData} placeholder="Naam van de contactpersoon"/>
              </div>
            )
          },
          {
            title: "Wat is het email-adres van de eerste contact persoon?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <Input required title="E-mail" name="email" value={data?.email} setValue={setData} placeholder="Email van de contactpersoon"/>
              </div>
            )
          },
          {
            title: "Wat is het telefoonnummer van de eerste contact persoon?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <Input required title="Telefoon" name="telefoon" value={data?.telefoon} setValue={setData} placeholder="Telefoonnummer van de contactpersoon"/>
              </div>
            )
          },
          {
            title: "Wat is het adres van de locatie?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <div>
                  <Input required title="Adres" name="adres" value={data?.adres} setValue={setData} placeholder="Adres van de locatie"/>
                  <Input required title="Huisnummer" name="huisnummer" value={data?.huisnummer} setValue={setData} placeholder="Huisnummer van de locatie"/>
                </div>
              </div>
            )
          },
          {
            title: "Wat is de postcode van de locatie?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <div>
                  <Input required title="Postcode" name="postcode" value={data?.postcode} setValue={setData} placeholder="Postcode van de locatie"/>
                  <Input required title="Plaats" name="plaats" value={data?.plaats} setValue={setData} placeholder="Plaats van de locatie"/>
                </div>
              </div>
            )
          },
          {
            title: "Zijn er nog opmerkingen over de locatie?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <Input title="Opmerkingen" rows={5} name="opmerkingen" value={data?.opmerkingen} setValue={setData} placeholder="Opmerkingen over de locatie"/>
              </div>
            )
          },
          {
            title: "Kloppen deze gegevens?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <div>{generateOverview(data)}</div>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Button disabled={requestSend} type="primary" title="Ja, opslaan" callback={() => save()} styles={{width: '100%'}}/>
                </div>
              </div>
            )
          },
        ]}
      />
		</>
	);
}

function generateOverview(data) {
  if (!data) return null
  const filter = ["organisatieID", "isActief"]

  return Object.keys(data).map(item => {
    if (filter.includes(item)) return null
    if (typeof data[item] === 'boolean') data[item] = data[item] ? "Ja" : "Nee"

    return (
      <div className={beheer_logic.recordContainer} key={item} style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'var(--primary-text-color)'}}>
        <p className={beheer_logic.recordText}>{item}</p>
        <p className={beheer_logic.recordText} style={{textTransform: 'unset'}}>{data[item]}</p>
      </div>
    )
  })
}

function checkInput(data, organisaties) {
  if (!data.organisatieID) return {success: false, message: 'Er is geen organisatie geselecteerd.'}
  if (organisaties.values.find(item => parseInt(item.organisatieID) === parseInt(data?.organisatieID)) === undefined) return {success: false, message: 'Geselecteerde organisatie bestaat niet.'}

  if (!data?.naam) return { success: false, message: 'Voer een locatie naam in.'}
  if (!data?.contactpersoon) return { success: false, message: 'Voer een contactpersoon in.'}
  if (!data?.email) return { success: false, message: 'Voer een emailadres in.'}
  if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(data?.email)) return { success: false, message: 'Voer een geldig emailadres in. (voorbeeld@gmail.com)'}
  if (!data?.telefoon) return { success: false, message: 'Voer een telefoonnummer in.'}
  if (!data?.adres) return { success: false, message: 'Voer een adres in.'}
  if (!data?.huisnummer) return { success: false, message: 'Voer een huisnummer in.'}
  if (!data?.postcode) return { success: false, message: 'Voer een postcode in.'}
  if (!/^[0-9]{4}[A-Z]{2}$/.test(data?.postcode)) return { success: false, message: 'Voer een geldige postcode in. (1234AB)'}
  if (!data?.plaats) return { success: false, message: 'Voer een plaats in.'}
  return {success: true}
}