import { useState, useContext, useEffect } from 'react'

import { PagerContext, TegelList } from "../components";
import { Config } from '../pageConfig';

import { DataStorageContext } from '../logic';
import { dashboard_logic, dashboard_style } from '../styles';
import { KwaliteitLogo, PowerOffIcon } from '../Images'
export default function Dashboard() {
  const pager = useContext(PagerContext);
  const {readData, saveData} = useContext(DataStorageContext);
  const [permission, setPermission] = useState({})
  const [buttons, setButtons] = useState([{
    name: 'Uitloggen',
    callback: () => pager.signout(),
    icon: PowerOffIcon
  }])

  useEffect(() => {
    pager.allowed_on_page();
    const fetchData = async () => {
      setPermission(await readData('permission'))
    }
    fetchData()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getButtons()
    // eslint-disable-next-line
  }, [permission])

  const getButtons = () => {
    const _buttons = [];
    Object.keys(Config)
      .filter((key) => {
        const page = Config[key];
        return page.showInNavbar && permission.success && page.permission.includes(permission.values.rechtengroep);
      })
      .forEach((key) => {
        const page = Config[key];
        const newItem = {
          name: page.name,
          callback: () => saveData('animation', {name: 'page_transition', callback: () => pager.navigateTo(page.url)}),
          icon: page.icon
        };
        _buttons.push(newItem);
      });
    _buttons.push({
      name: 'Uitloggen',
      callback: () => saveData('animation', {name: 'page_out_transition', callback: () => pager.signout()}),
      icon: PowerOffIcon
    });
    setButtons(_buttons);
  };

  return (
    <div className={dashboard_logic.container}>
      <div className={[dashboard_logic.logoContainer, dashboard_style.logoContainer].join(' ')}>
        <img src={KwaliteitLogo} className={dashboard_logic.logo} alt="Kwaliteit @ Logo"></img>
      </div>
      <TegelList data={buttons}/>
    </div>
  );
}