import React, { useContext, useEffect, useState } from "react";
import {  PagerContext, Popup, BackButton, Input, Dropdown, useSwipe, Button, DateInput } from "../components";
import { DataStorageContext, fetchMedia, deleteRequest } from "../logic";
import { ArrowBack, BelovenIcon, DeleteIcon, FilterIcon, UserIcon } from "../Images";
import { bekijken_logic, bekijken_style } from "../styles";
export default function Bekijken() {
  const pager = useContext(PagerContext)
  const { readData, clearData, saveData } = useContext(DataStorageContext)
  
  const [reports, setReports] = useState([])
  const [baseReports, setBaseReports] = useState([])
  const [activeReport, setActiveReport] = useState(0)
  const [permission, setPermission] = useState(false)
  const [selectedBelofte, setSelectedBelofte] = useState('false')

  const [bewoner, setBewoner] = useState('')
  const [beloften, setBeloften] = useState([])
  const [datum, setDatum] = useState()
  const [search, setSearch] = useState('')

  const [mediaPopup, setMediaPopup] = useState(false)
  const [deletePopup, setDeletePopup] = useState(false)
  const [popupActive, setPopupActive] = useState(false)
  const [infoPopup, setInfoPopup] = useState(false)
  useEffect(() => {
    const fetchData = async () => {
      await pager.allowed_on_page()

      setPermission(await readData('permission'))
      const reports = await readData('bekijken')
      setReports(reports)
      setBaseReports(reports)
      getMedia(reports);

      setBeloften(generateDropdownValues(await readData('beloftes')))

      setActiveReport(0);
    };
    fetchData();
    // eslint-disable-next-line
  }, [])

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear().toString().substr(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const generateDropdownValues = (beloften) => {
    if (!beloften.success) return []
    return [{name: 'Geen selectie.', value: false}, ...beloften.values.map((belofte) => {
      return {name: `${belofte.nummer}. ${belofte.belofte}`, value: belofte.belofte}
    })]
  }

  useEffect(() => {
    if (!baseReports.values || baseReports.values.length === 0) return;
    const _reports = baseReports.values.filter((item) => {
      return (
        (!bewoner || (bewoner && item.bewoner.toLowerCase().includes(bewoner.toLowerCase()))) &&
        (selectedBelofte === 'false' || (selectedBelofte && item.belofte.toLowerCase() === selectedBelofte.toLowerCase())) &&
        (!datum || (datum && formatDate(item.datum) === formatDate(new Date(datum)))) &&
        (!search || (search && ((search.startsWith('#') && search.length === 1) || item.tekst.toLowerCase().includes(search?.toLowerCase()) || (search.startsWith('#') && String(item.rapportID) === search.slice(1))))))
    })
    if (_reports.length === 0) return setReports(baseReports)
    setReports({success: true, values: _reports})
    setActiveReport(0)
    // eslint-disable-next-line
  }, [bewoner, selectedBelofte, datum, search])

  useEffect(() => {
    getMedia(reports);
    // eslint-disable-next-line
  }, [activeReport]);

  const deleteReport = async () => {
    let data;
    setDeletePopup(false);

    if (!reports.values[activeReport]) return saveData('notification', {type: 'error', message: 'Er is iets fout gegaan tijdens het verwijderen van het item.'})
    await deleteRequest('/api/data/bekijken', {
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: reports.values[activeReport]?.rapportID
      })
    }).then((response) => {data = response})
    if (!data.success) return saveData('notification', {type: 'error', message: 'Er is iets fout gegaan tijdens het verwijderen van het item.'})
    clearData('bekijken')
    setReports(await readData('bekijken'))
    saveData('notification', {type: 'success', message: 'Het item is succesvol verwijderd.'})
    setActiveReport(0)
  }

  // const getMedia = async (reports) => {
  //   let _mediaList = [...mediaList]
  //   if (!reports || reports.length === 0) return;
  //   for (let i = activeReport; i <= activeReport + 2 && i < reports.values.length; i++) {
  //     const report = reports.values[i]
  //     if (report.hasMedia === 0) continue;
  //     if (_mediaList.find(media => media.id === report.rapportID)) continue;
  //     const data = await fetchMedia(`/api/data/media?${new URLSearchParams({id: report.path})}`, {})
  //     if (data.status === 500) continue;
  //     const mediaBlob = await data.blob();
  //     const media = {id: report.rapportID, media: URL.createObjectURL(mediaBlob), type: data.headers.get('content-type').startsWith('image') ? 'image' : 'video'};
  //     _mediaList.push(media);
  //   }
  //   setMediaList(_mediaList);
  // }

  const getMedia = async (reportList) => {
    let index = 0;
    reportList = reportList ? reportList : reports

    if (!reportList.success) return;
    const _reports = await Promise.all(reportList.values.map(async (report) => {
      if (index >= activeReport + 10) return report
      // if (searchValue && !bewoner.naam.toLowerCase().includes(searchValue.toLowerCase())) return report;
      index = index + 1;
      if (typeof report.path === 'string' && !report.path.startsWith('blob')) {
        const data = await fetchMedia(`/api/data/media?${new URLSearchParams({id: report.path})}`, {})
        if (data.status === 500) { report.path = null; return report}
        const blob = await data.blob()
        report.path = URL.createObjectURL(blob)
        report.type = data.headers.get('content-type').startsWith('image') ? 'image' : 'video'
      }
      return report
    }))

    setReports({ success: true, values: _reports });
    await saveData('bekijken', _reports );

    return true;
  }

  const swipeHandlers = useSwipe({
    onSwipedLeft: () => {
      setActiveReport(activeReport + 1 >= reports.values.length ? reports.values.length - 1 : activeReport + 1);
    },
    onSwipedRight: () => {
      setActiveReport(activeReport - 1 <= 0 ? 0 : activeReport - 1)
    }
  });

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <BackButton/>
        {permission && [1,2,3].includes(permission.values.rechtengroep) ? <button className={[bekijken_logic.backButton, bekijken_style.backButton].join(' ')} onClick={() => setDeletePopup(true)}><DeleteIcon/></button> : null}
      </div>
      <div style={{width: 'calc(90% - 50px)', margin: '25px auto'}} {...swipeHandlers}>

        <div className={bekijken_logic.navigatorWrapper}>
          {activeReport >= 1 ? <ArrowBack className={[bekijken_logic.back, bekijken_style.back].join(' ')} onClick={() => setActiveReport(activeReport - 1 <= 0 ? 0 : activeReport - 1)}/> : <div className={bekijken_logic.back}></div>}

          <div style={{width: '60%'}}>
            <button className={[bekijken_logic.inputIconField, bekijken_logic.filterButton, bekijken_style.inputField].join(" ")} onClick={() => {setPopupActive(true)}}>Filters</button>
            <div className={[bekijken_logic.inputIcon, bekijken_style.inputIcon].join(" ")}><FilterIcon/></div>
          </div>

          {activeReport < reports.values.length - 1 ? <ArrowBack className={[bekijken_logic.next, bekijken_style.next].join(' ')} onClick={() => setActiveReport(activeReport + 1 >= reports.values.length ? reports.values.length : activeReport + 1)}/> : <div className={bekijken_logic.next}></div>}
        </div>


        <div>
          {reports.values[activeReport]?.type === 'video' ?
            <div onClick={() => setMediaPopup(true)}><video className={[bekijken_logic.mediaContainer, bekijken_style.mediaContainer].join(' ')} src={reports.values[activeReport]?.path}/></div>
          : reports.values[activeReport]?.type === 'image' ?
            <div onClick={() => setMediaPopup(true)}><img alt="" src={reports.values[activeReport]?.path} className={[bekijken_logic.mediaContainer, bekijken_style.mediaContainer].join(' ')}></img></div>
          : <div className={[bekijken_logic.mediaContainer, bekijken_style.mediaContainer].join(' ')}></div>}

          {reports.values[activeReport] ? <p className={[bekijken_logic.geboortedatum, bekijken_style.geboortedatum].join(" ")}>{reports.values[activeReport] ? formatDate(reports.values[activeReport]?.datum) : null}</p> : null}
          {/* <div className={[bekijken_logic.medewerker, bekijken_style.medewerker].join(" ")} onClick={() => setInfoPopup(true)}><UserIcon/></div> */}
          {/* <div popovertarget="belofte_popover" className={[bekijken_logic.belofte, bekijken_style.belofte].join(" ")} onClick={() => setInfoPopup(true)}><BelovenIcon/></div> */}
        </div>


        <div className={bekijken_logic.textWrapper}>
          <p className={[bekijken_logic.activeReportText, bekijken_style.text].join(" ")}>{reports.values[activeReport]?.tekst}</p>
        </div>

        
        <div>
          <p className={[bekijken_logic.footerText, bekijken_style.text].join(" ")}><UserIcon style={{fill: '#e6e0ec'}}/>{reports.values[activeReport]?.bewoner}</p>
          <p className={[bekijken_logic.footerText, bekijken_style.text].join(" ")}><BelovenIcon style={{fill: '#e6e0ec'}}/>{reports.values[activeReport]?.belofte}</p>
          <p className={[bekijken_logic.footerText, bekijken_style.text].join(" ")}><UserIcon style={{fill: '#e6e0ec'}}/>{reports.values[activeReport]?.medewerker}</p>
        </div>

        {popupActive && <Popup style={{maxWidth: '80%', maxHeight: '80%', backgroundColor: '#5ca7dc', border: 'none'}} toggle={setPopupActive}>
          <>
            {permission !== 6 && <div style={{width: '100%'}}><Input placeholder="Naam van bewoner" title="Bewoner"value={bewoner} styles={{padding: '0 15px', width: 'calc(100% - 30px)'}} setValue={setBewoner}/></div>}          
            <Dropdown title="Belofte" dropdownValues={beloften} value={selectedBelofte} setValue={setSelectedBelofte} styles={{borderRadius: '0px', textAlign: 'start', width: '100%'}}/>
            <DateInput type="date" title="Datum" styles={{borderRadius: '0px', textAlign: 'start'}} value={datum} setValue={setDatum}/>
            <Input placeholder="Zoek op trefwoorden" title="Zoeken" value={search} styles={{padding: '0 15px', width: 'calc(100% - 30px)'}} setValue={setSearch}/>
            <Button type="primary" title='Sluiten' callback={() => {setPopupActive(false)}} styles={{marginTop: '10px', padding: '5px', width: '100%', fontSize: '1rem', height: 'unset'}}/>
          </>
        </Popup>}

        {mediaPopup && <Popup style={{maxWidth: '80%', maxHeight: '80%', overflow: 'hidden', backgroundColor: '#5ca7dc', border: 'none'}} toggle={setMediaPopup}>
          <>
            {reports.values[activeReport]?.type === 'video' ?
              <video className={bekijken_logic.media} src={reports.values[activeReport]?.path} controls autoPlay onEnded={() => setMediaPopup(false)}/>
            : 
            <img alt="" src={reports.values[activeReport]?.path} className={bekijken_logic.media}></img>
            }
          </>
        </Popup>}

        {deletePopup && <Popup style={{maxWidth: '80%', backgroundColor: '#5ca7dc', border: 'none'}} toggle={setDeletePopup}>
          <h3 className={bekijken_style.text} style={{textAlign: 'center'}}>Weet je zeker dat je rapportage<br/> #{reports.values[activeReport] ? reports.values[activeReport].rapportID : null}<br/>wilt verwijderen?</h3>
          <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '30px'}}>
            <Button type="primary" title="Ja" callback={deleteReport}/>
            <Button type="primary" title="Nee" callback={() => setDeletePopup(false)}/>
          </div>
        </Popup>}

        {infoPopup && <Popup style={{maxWidth: '80%', backgroundColor: '#5ca7dc', border: 'none'}} toggle={setInfoPopup}>
          <>
            <h3 className={bekijken_style.text} style={{margin: 0}}>Rapport Informatie</h3>
            <p className={bekijken_style.text} style={{margin: '2px 0'}}>Rapport ID: #{reports.values[activeReport]?.rapportID}</p>
            <p className={bekijken_style.text} style={{margin: '2px 0'}}>Medewerker: {reports.values[activeReport]?.medewerker}</p>
            <p className={bekijken_style.text} style={{margin: '2px 0'}}>Belofte: {reports.values[activeReport]?.belofte}</p>
            <p className={bekijken_style.text} style={{margin: '2px 0'}}>Datum: {reports.values[activeReport] ? formatDate(reports.values[activeReport].datum) : null}</p>
          </>
        </Popup>}


      </div>
    </>
  );
}