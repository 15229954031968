import { useContext, useEffect } from "react";
import { Header, ListButton, PagerContext } from "../../../components";
export default function Applicatie() {
  const pager = useContext(PagerContext)

	useEffect(() => {
		const fetchData = async () => {
			await pager.allowed_on_page()
		};
		fetchData();
		// eslint-disable-next-line
	}, [pager.arguments]);

	return (
		<>
			<Header title="Applicatie" backUrl="/beheer" playAnimation={false}/>
            <ListButton title="Organisaties" callback={() => pager.navigateTo(`/beheer/applicatie/organisatie`)}/>
            <ListButton title="Locaties" callback={() => pager.navigateTo(`/beheer/applicatie/locatie`)}/>
            <ListButton title="Feedback" callback={() => pager.navigateTo(`/beheer/applicatie/feedback`)}/>
		</>
	);
}
