import { useContext, useEffect, useState } from "react";
import { PagerContext, ResultList, Header, Search, Button } from "../../../components";
import { DataStorageContext } from "../../../logic";
export default function ApplicatieLocatie() {
  const pager = useContext(PagerContext)
  const { readData } = useContext(DataStorageContext)
  const [searchValue, setSearchValue] = useState('')
  const [locaties, setLocaties] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      await pager.allowed_on_page()
      setLocaties(await readData('beheer_locaties'))
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

	return (
		<>
      <Header 
        title="Locaties"
        backUrl="/beheer/applicatie"
        playAnimation={false}
      />

      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'end', width: '100%'}}>
        <div style={{width: '65%'}}><Search title="Zoeken" placeholder="Typ om te zoeken" value={searchValue} setValue={setSearchValue}/></div>
        <div style={{width: '30%'}}><Button title="Toevoegen" type="primary" styles={{height: '34px', width: '100%', fontSize: '1rem'}} callback={() => pager.navigateTo('/beheer/applicatie/locatie/nieuw')}/></div>
      </div>

      <ResultList 
        showResults
        title={(item) => item.naam}
        data={locaties.values} 
        url={(locatie) => {return `/beheer/applicatie/locatie/edit?locatie=${locatie.locatieID}`}} 
        filter={(locaties) => {
          const searchParts = searchValue.toLowerCase().split(' ').filter(part => part.trim() !== '');
          return searchParts.length === 0 ||
            searchParts.every(part =>
              String(locaties.locatieID).toLowerCase().includes(part) ||
              locaties.naam.toLowerCase().includes(part) ||
              locaties.adres.toLowerCase().includes(part) ||
              locaties.huisnummer.toLowerCase().includes(part) ||
              locaties.postcode.toLowerCase().includes(part) ||
              locaties.plaats.toLowerCase().includes(part) ||
              locaties.contactpersoon.toLowerCase().includes(part)
            );
          }} 
      />
		</>
	);
}
