const keys = [
  ''
]

onLoad();
function onLoad() {
  for (let i = localStorage.length - 1; i >= 0; i--) {
    const key = localStorage.key(i);
    if (key && !keys.includes(key)) {
      localStorage.removeItem(key);
    }
  }
  
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);

    if (!key) return;


    const storedData = localStorage.getItem(key);
    const dataObject = storedData ? JSON.parse(storedData) : [];

    const newDataObject = [];
    if (Array.isArray(dataObject)) {
      dataObject.forEach((item) => {
        if (new Date().toISOString() >= item.validTill) {
          return;
        }

        newDataObject.push(item);
      });

      localStorage.setItem(key, JSON.stringify(newDataObject));
    } else if (typeof dataObject === "object") {
      if (new Date().toISOString() >= dataObject.validTill) {
        localStorage.removeItem(key);
      }
    }
  }
}

/**

 * Save data to the local storage

 * @param key key of the Storage object to save to

 * @param data the object to save to localstorage

 * @param duration the duration of the object in days 

*/
export function saveToLocal(key, data, duration) {
  const dataObject = {
    validTill: new Date(new Date().setDate(new Date().getDate() + duration)),
    ...data,
  };

  localStorage.setItem(key, JSON.stringify(dataObject));
}

/**

 * Append data to a existing localstorage object

 * If the localstorage object does not exist, it will create a new one.

 * @param key key of the Storage object to append to

 * @param data the object to add to localstorage

 * @param duration the duration of the object in days 

*/

export function appendToLocal(key, data, duration) {
  const storedData = localStorage.getItem(key);

  const dataObject = storedData ? JSON.parse(storedData) : [];

  const newDataObject = [
    ...dataObject,
    {
      validTill: new Date(new Date().setDate(new Date().getDate() + duration)),
      ...data,
    },
  ];

  localStorage.setItem(key, JSON.stringify(newDataObject));
}

/**

 * Get data from localstorage

 * @param key key of the Storage object to retrive

*/
export function getFromLocal(key) {
  const data = localStorage.getItem(key);
  if (!data) return;
  return JSON.parse(data);
}
