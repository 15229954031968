import React, { useContext, useEffect, useState } from "react";
import { PagerContext, Header } from "../components";
import { DataStorageContext, fetchMedia } from "../logic";
import { bekijken_logic } from "../styles";
export default function BekijkRapportage() {
  const pager = useContext(PagerContext)
  const { readData } = useContext(DataStorageContext)
  
  const [report, setReport] = useState([])

  const [permission, setPermission] = useState(false)
  const [mediaList, setMediaList] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      await pager.allowed_on_page()
      const rapportages = await readData('bekijken')
      const rapportage = rapportages.values.find(rapportage => String(rapportage.rapportID) === String(pager.getArgument('id')))
      if (!rapportage) return pager.navigateTo('/bekijken')
      setReport(rapportage)
      setPermission(await readData('permission'))
      getMedia();
    };
    fetchData();
    // eslint-disable-next-line
  }, [pager.arguments])

  const getMedia = async () => {
    try {
      let media;
      const id = pager.getArgument('id');
      const data = await fetchMedia(`/api/data/media?${new URLSearchParams({id: id})}`, {})
      if (data.status === 500) return setMediaList(false)
      if (data.headers.get('content-type').startsWith('image')) {
        const mediaBlob = await data.blob();
        media = {media: URL.createObjectURL(mediaBlob), type: 'image'};
      } else {
        const mediaBlob = await data.blob();
        media = {media: URL.createObjectURL(mediaBlob), type: 'video'};
      }
      setMediaList(media)
    } catch (error) {
      console.error('Error fetching media:', error);
    }
  };


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear().toString().substr(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };
  return (
    <>
      <Header
        title={`Rapportage - ${report.bewoner}`}
        deleteButton={permission.values && [1,2,3].includes(permission.values.rechtengroep) ? {
          naam: `${report.bewoner} - ${report.belofte} (${formatDate(report.datum)})`,
          endpoint: '/api/data/bekijken',
          id: report.rapportID,
          dataStorageName: 'bekijken',
          returnUrl: () => pager.navigateTo(`/bekijken`)
        } : null}
      />
      <div className={bekijken_logic.wrapper}>
        <div className={bekijken_logic.verslagWrapper}>
          <p className={bekijken_logic.tekst}>{report.tekst}</p>
          <div className={bekijken_logic.mediaWrapper}>
            {mediaList && mediaList.type === 'video' ? (
                <video className={bekijken_logic.video} src={mediaList.media} controls/>
              ) : (
                <img className={bekijken_logic.media} src={mediaList.media} alt=""/>
            )}
          </div>
        </div>
        <div className={bekijken_logic.footer}>
          <div style={{width: '100%'}}>{report.medewerker}</div>
          <div style={{width: '100%', textAlign: 'right'}}>{formatDate(report.datum)}</div>
        </div>
      </div>
    </>
  )
}