import { useContext, useEffect, useState } from "react";
import { ListButton, PagerContext, Header } from "../../../components";
import { DataStorageContext } from "../../../logic";
export default function Locatie() {
  const pager = useContext(PagerContext)
  const { readData, clearData } = useContext(DataStorageContext)
  const [locatie, setLocatie] = useState({})

	useEffect(() => { 
		const fetchData = async () => {
			await pager.allowed_on_page()
			const locaties = await readData('beheer_locaties')
			let _locatie;		

			if (typeof locaties.values === 'object' && locaties.values !== null) {
				const firstKey = Object.keys(locaties.values)[0];
				if (typeof locaties.values[firstKey] === 'object' && locaties.values[firstKey] !== null) {
					_locatie = Object.values(locaties.values).find((locatie) => String(locatie.locatieID) === pager.getArgument('locatie')) || {};
					if (Object.keys(_locatie).length === 0) return pager.navigateTo('/beheer');
					setLocatie(_locatie)
				  }
				} else {
					setLocatie(locaties.values)
				}
			  }
			clearData('beheer_accounts')
			clearData('beheer_bewoners')
			clearData('beheer_beloftes')
		fetchData();
		// eslint-disable-next-line
	}, [pager.arguments]);

	return (
		<>
			<Header title={locatie.naam} backUrl="/beheer" playAnimation={false}/>

			<ListButton title="Accounts" callback={() => pager.navigateTo(`/beheer/locatie/accounts?locatie=${locatie.locatieID}`)}/>
			<ListButton title="Bewoners" callback={() => pager.navigateTo(`/beheer/locatie/bewoners?locatie=${locatie.locatieID}`)}/>
			{/* <ListButton title="Quiz" callback={() => saveData('notification', {type: 'error', message: `Dit onderdeel is nog in ontwikkeling`})}/>
			{/* <ListButton title="Facturatie" callback={() => pager.navigateTo(`/beheer/locatie/facturatie?locatie=${locatie.locatieID}`)}/> */}
			{/* <ListButton title="Facturatie" callback={() => saveData('notification', {type: 'error', message: `Dit onderdeel is nog in ontwikkeling`})}/> */}
			<ListButton title="Logs" callback={() => pager.navigateTo(`/beheer/locatie/logs?locatie=${locatie.locatieID}`)}/>
			<ListButton title="Beloftes" callback={() => pager.navigateTo(`/beheer/locatie/beloftes?locatie=${locatie.locatieID}`)}/>
		</>
	);
}
