import { PagerContext, PagerProvider }  from './contexts/pager.js';
import { NotificationController, NotificationContext} from './contexts/notifications.js';
import { InputFactory }                 from './assets/inputFactory.js'
import { Popup }                        from './assets/popup';
import Animations                   from './assets/animations.js';
import Background                       from './assets/background.js';
import ResultList                       from './assets/resultList.js';  
import Header                           from './assets/header.js';
import TegelList                        from './assets/tegelList.js';
import Steps                            from './assets/steps.js';
import useSwipe                           from './assets/swiper.js';  
import Button                           from './assets/elements/button.js';
import Input                            from './assets/elements/input.js';
import Password                         from './assets/elements/password.js';
import Search                           from './assets/elements/search.js';
import Delete                           from './assets/elements/delete.js';
import ListButton                       from './assets/elements/listButton.js';
import MenuButton                       from './assets/elements/menuButton.js';
import BackButton                       from './assets/elements/back.js';     
import Dropdown                         from './assets/elements/dropdown.js';
import DateInput                        from './assets/elements/date.js';
import Checkbox                         from './assets/elements/checkbox.js';
import Image                            from './assets/elements/image.js';
export {
  PagerContext,
  PagerProvider,
  NotificationController,
  NotificationContext,
  InputFactory,
  Animations,
  Popup,
  Background,
  ResultList,
  Header,
  TegelList,
  Steps,
  useSwipe,
  
  Button,
  Input,
  Password,
  Search,
  Delete,
  ListButton,
  MenuButton,
  BackButton,
  Dropdown,
  DateInput,
  Checkbox,
  Image

}

export function assertUnreachable(message) {
  throw new Error("Unreachable assertion: " + message);
}