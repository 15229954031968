import { useContext, useEffect, useState, useRef} from "react";
import { Button, DateInput, Input, PagerContext, NotificationContext, Header, Delete, Steps } from "../../../components";
import { DataStorageContext, postRequest } from "../../../logic";
import { beheer_logic, beleven_logic } from "../../../styles";
export default function BewonersNieuw() {
  const imageInputRef = useRef()
  const pager = useContext(PagerContext)
  const { readData, clearData } = useContext(DataStorageContext)
  const notificationController = useContext(NotificationContext)

  const [requestSend, setRequestSend] = useState(false)
  const [data, setData] = useState({
    naam: '',
    geboortedatum: '',
    locatieID: '',
    profielfoto: '',
    profielfoto_path: '',
  })

  // eslint-disable-next-line
  const [uploading, setUploading] = useState(false)
  
  useEffect(() => {
    const fetchData = async () => {
      await pager.allowed_on_page()
      const locaties = await readData('beheer_locaties')
      if (Object.keys(locaties.values).length === 0) return pager.navigateTo(`/beheer`)
      if (locaties.success && !locaties.values.find((locatie) => locatie.locatieID === parseInt(pager.getArgument('locatie')))) return pager.navigateTo(`/beheer`)
      const locatieID = locaties.values.find((locatie) => locatie.locatieID === parseInt(pager.getArgument('locatie'))).locatieID
      setData(prev => ({...prev, locatieID: locatieID}))
    };
    fetchData();
    // eslint-disable-next-line
  }, [pager.arguments]);

  const save = async () => {
    setRequestSend(true);
    let result;
    const checkResult = checkInput(data)
    if (!checkResult.success) {setRequestSend(false); return notificationController.sendMessage({type: 'error', message: checkResult.message})}
    await postRequest('/api/data/bewoner', {
      headers: {
      'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        naam: data.naam,
        geboortedatum: data.geboortedatum.split('T')[0],
        profielfoto: data.profielfoto_path,
        locatieID: data.locatieID,
      })
    }).then((response) => {result = response})
    if (!result.success) {setRequestSend(false); return notificationController.sendMessage({type: 'error', message: 'Er is iets fout gegaan tijdens het aanmaken van de bewoner. Probeer het opnieuw.'})}
    clearData('beheer_bewoners')
    readData('beheer_bewoners')
    notificationController.sendMessage({type: 'success', message: 'De bewoner is succesvol aangemaakt.'})
    pager.navigateTo(`/beheer/locatie/bewoners?locatie=${pager.getArgument('locatie')}`)
  }

  const onImageChange = async (e, handleSwipe) => {
    setUploading(true);
    const file = e.target.files[0];

    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
      return notificationController.sendMessage({type: 'error', message: `Ongeldig bestandstype. Alleen PNG & JPEG bestanden zijn toegestaan.`});
    }
    
    let result;
    const formData = new FormData();
    formData.append("type", "bewoners");

    const imageFile = await fetch(URL.createObjectURL(file))
    .then((res) => res.blob())
    .then((blob) => new File([blob], "image.jpg", { type: "image/jpeg" }));
    formData.append("media", imageFile);


    await postRequest('/api/data/media', {
      body: formData
    }).then((response) => {result = response})
    if (!result.success) {setUploading(false); return notificationController.sendMessage({type: 'error', message: `Er is iets mis gegaan tijdens het uploaden van het media bestand.`});}; // Error!
    
    setData(prev => ({
      ...prev,
      profielfoto_path: result.filename,
      profielfoto: URL.createObjectURL(file)
    }))
    setUploading(false);
    handleSwipe('left')
  }
  
  return (
    <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%'}}>

      <Header 
        title={`Bewoner: ${data?.naam || ""}`}
        backUrl={`/beheer/locatie/bewoners?locatie=${pager.getArgument('locatie')}`}
        playAnimation={false}
      />

      <Steps
        steps={[
          {
            title: "Wat is de naam van de bewoner?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <Input required title="Naam" name="naam" value={data?.naam} setValue={setData} placeholder="Naam van de bewoner"/>
              </div>
            )
          },
          {
            title: "Wat is de geboortedatum?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <DateInput type="date" required name="geboortedatum" title="Geboortedatum" value={data?.geboortedatum} setValue={setData} placeholder="Geboorte datum"/>
              </div>
            )
          },
          {
            title: "Upload een profiel foto...",
            content: (handleSwipe) => (
              <>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <div style={{width: '100%'}}>
                    <Button styles={{fontSize: '.9rem', width: '100%'}} type="primary" title="Media bestand selecteren" callback={() => imageInputRef.current.click()}/>
                  </div>
                </div>

                <div className={beleven_logic.imgWrapper}>
                  {data?.profielfoto.startsWith('blob') && <img src={data?.profielfoto}></img>}
                </div>
                <input 
                  ref={imageInputRef} 
                  type="file" 
                  onChange={(e) => onImageChange(e, handleSwipe)} 
                  style={{display: 'none'}} 
                  accept="image/png, image/jpg, image/jpeg, video/mp4" 
                  capture="environment"
                />
              </>
            )
          },
          {
            title: "Kloppen deze gegevens?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <div>{generateOverview(data)}</div>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Button disabled={requestSend || uploading} type="primary" title="Ja, opslaan" callback={() => save()} styles={{width: '100%'}}/>
                </div>
              </div>
            )
          },
        ]}
      />
      
    </div>
  );
}

function generateOverview(_data) {
  const data = {..._data}
  if (!data) return null
  const filter = ['bewonerID', 'locatieID', 'isActief', 'profielfoto_path']

  return Object.keys(data).map(item => {
    if (filter.includes(item)) return null

    if (item === 'geboortedatum') data[item] = convertDate(data[item])

    if (item === 'profielfoto') {

      if (!data[item].startsWith('blob')) return null

      return (
        <div className={beheer_logic.recordContainer} key={item} style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start', color: 'var(--primary-text-color)'}}>
          <p className={beheer_logic.recordText}>Profiel Foto</p>
          <img style={{borderRadius: '8px', width: '40%', height: '40%', aspectRatio: '1/1', objectFit: 'cover'}} src={data.profielfoto}></img>
        </div>
      )
    }

    return (
      <div className={beheer_logic.recordContainer} key={item} style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'var(--primary-text-color)'}}>
        <p className={beheer_logic.recordText}>{item}</p>
        <p className={beheer_logic.recordText} style={{textTransform: 'unset'}}>{data[item]}</p>
      </div>
    )
  })
}

function convertDate(dateString) {
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;

  const today = new Date();
  let age = today.getFullYear() - year;
  const monthDiff = today.getMonth() - date.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < date.getDate())) {
    age--;
  }

  return `${formattedDate}, ${age} jaar`;
}

function checkInput(data) {
  if (!data.naam) return { success: false, message: 'Voer een naam in.'}
  if (!data.geboortedatum) return { success: false, message: 'Voer een geboortedatum in.'}
  return {success: true}
}