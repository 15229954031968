import { button_logic, button_style } from '../../../styles'
import { useContext } from 'react';
import { PagerContext } from '../../';
import { ArrowBack } from '../../../Images';
import { DataStorageContext } from '../../../logic';

/**
 * Represents a back button component.
 * @param {string} props.className - The class name for the button.
 * @param {Object} props.styles - The styles object for the button.
 * @param {string} props.type - The type of the button.
 * @param {boolean} props.disabled - Indicates if the button is disabled.
 * @param {number} props.tabIndex - The tab index for the button.
 * @param {string} props.url - The URL to navigate to when the button is clicked.
 * @returns {JSX.Element} The back button component.
 */
export default function BackButton({ className, styles, type, disabled, tabIndex, url, playAnimation = true }) {
    let element_styles = {};
    if (styles || typeof styles === "object") element_styles = styles

    const classes = [
        Array.isArray(className) ? className.join(' ') : className,
        button_logic.backButton,
        disabled ? button_logic.disabled : '',
        type === 'primary' ? button_style.backButton : button_style.backButton
      ].join(' ');
    
    const pager = useContext(PagerContext)
    const { saveData } = useContext(DataStorageContext)
  
    return <div className={button_logic.backButton_wrapper}>
            <button 
                tabIndex={tabIndex}
                disabled={disabled}
                className={classes} 
                style={element_styles}
                onClick={() => !disabled && playAnimation ? saveData('animation', {name: 'page_out_transition', callback: () => pager.navigateTo(url ? url : '/dashboard')}) : pager.navigateTo(url ? url : '/dashboard')}>
                    <ArrowBack/>
            </button>
          </div>
  }