import { useContext, useEffect, useState } from "react";
import { Button, Checkbox, Input, PagerContext, Steps, Header, NotificationContext } from "../../../components";
import { DataStorageContext, postRequest } from "../../../logic";
import { beheer_logic, beheer_style } from "../../../styles";
export default function OrganisatieNieuw() {
  const pager = useContext(PagerContext)
  const notificationController = useContext(NotificationContext)
  const { clearData, readData, saveData } = useContext(DataStorageContext)
  
  const [requestSend, setRequestSend] = useState(false)

  const [data, setData] = useState({
    naam: '',
    contactpersoon: '',
    email: '',
    telefoon: '',
    adres: '',
    huisnummer: '',
    postcode: '',
    plaats: '',
    opmerkingen: '',
    identiek: false
  })

  useEffect(() => {
    const fetchData = async () => {
      await pager.allowed_on_page()
    };
    fetchData();
    // eslint-disable-next-line
  }, [pager.arguments]);

  const save = async () => {
    setRequestSend(true);
    let result;
    let locatieData;
    const checkResult = checkInput(data)
    if (!checkResult.success) {setRequestSend(false); return notificationController.sendMessage({type: 'error', message: checkResult.message})}
    await postRequest('/api/data/organisatie', {
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        naam: data.naam,
        contactpersoon: data.contactpersoon,
        email: data.email,
        telefoon: data.telefoon,
        adres: data.adres,
        huisnummer: data.huisnummer,
        postcode: data.postcode,
        plaats: data.plaats,
        opmerkingen: data.opmerkingen,
      })
    }).then((response) => {result = response})
    if (!result.success) {setRequestSend(false); return notificationController.sendMessage({type: 'error', message: 'Er is iets fout gegaan tijdens het aanmaken van de organisatie. Probeer het opnieuw.'})}

    if (data.identiek) {
      await postRequest('/api/data/locatie', {
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          organisatieID: result.values.organisatieID,
          naam: data.naam,
          contactpersoon: data.contactpersoon,
          email: data.email,
          telefoon: data.telefoon,
          adres: data.adres,
          huisnummer: data.huisnummer,
          postcode: data.postcode,
          plaats: data.plaats,
          opmerkingen: data.opmerkingen,
        })
      }).then((response) => {locatieData = response})

      if (!locatieData.success && locatieData.message === "INVALID_ADDRESS") notificationController.sendMessage({type: 'error', message: "Het adres is niet gevonden. De locatie is niet aangemaakt."})
      if (!locatieData.success) notificationController.sendMessage({type: 'error', message: 'Er is iets fout gegaan tijdens het aanmaken van de locatie. De locatie is niet aangemaakt.'})
      clearData('beheer_locaties')
      readData('beheer_locaties')
    }

    clearData('beheer_organisaties')
    readData('beheer_organisaties')

    data.identiek ? notificationController.sendMessage({type: 'success', message: 'Organisatie & locatie zijn succesvol aangemaakt.'}) : notificationController.sendMessage({type: 'success', message: 'Organisatie is succesvol aangemaakt.'})
    pager.navigateTo(`/beheer/applicatie/organisatie/edit?organisatie=${result.values.organisatieID}`)
  }

	return (
    <>
      <Header 
        title={`Organisatie: ${data?.naam}`}
        backUrl="/beheer/applicatie/organisatie"
        playAnimation={false}
      />

      <Steps
        steps={[
          {
            title: "Wat is de naam van de organisatie?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <Input required title="Naam" name="naam" value={data?.naam} setValue={setData} placeholder="Naam van de organisatie"/>
              </div>
            )
          },
          {
            title: "Wat is de naam van de eerste contact persoon?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <Input required title="Contactpersoon" name="contactpersoon" value={data?.contactpersoon} setValue={setData} placeholder="Naam van de contactpersoon"/>
              </div>
            )
          },
          {
            title: "Wat is het email-adres van de eerste contact persoon?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <Input required title="E-mail" name="email" value={data?.email} setValue={setData} placeholder="Email van de contactpersoon"/>
              </div>
            )
          },
          {
            title: "Wat is het telefoonnummer van de eerste contact persoon?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <Input required title="Telefoon" name="telefoon" value={data?.telefoon} setValue={setData} placeholder="Telefoonnummer van de contactpersoon"/>
              </div>
            )
          },
          {
            title: "Wat is het adres van de organisatie?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'start', height: '100%'}}>
                <Input required title="Straat" name="adres" value={data?.adres} setValue={setData} placeholder="Straat"/>
                <Input required title="Huisnummer" name="huisnummer" value={data?.huisnummer} setValue={setData} placeholder="Huisnummer"/>
              </div>
            )
          },
          {
            title: "Wat is de postcode van de organisatie?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'start', height: '100%'}}>
                <Input required title="Postcode" name="postcode" value={data?.postcode} setValue={setData} placeholder="Postcode van de organisatie"/>
                <Input required title="Plaats" name="plaats" value={data?.plaats} setValue={setData} placeholder="Plaats van de organisatie"/>
              </div>
            )
          },
          {
            title: "Zijn er nog opmerkingen over de organisatie?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <Input title="Opmerkingen" rows={5} name="opmerkingen" value={data?.opmerkingen} setValue={setData} placeholder="Opmerkingen over de organisatie"/>
              </div>
            )
          },
          {
            title: "Is de locatie identiek aan de organisatie?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <Checkbox title="Locatie is identiek" name="identiek" value={data?.identiek} setValue={setData} placeholder="Als dit vinkje aan staat word er automatisch een locatie aangemaakt met de zelfde gegevens."/>
              </div>
            )
          },
          {
            title: "Kloppen deze gegevens?",
            content: (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
                <div>{generateOverview(data)}</div>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Button disabled={requestSend} type="primary" title="Ja, opslaan" callback={() => save()} styles={{width: '100%'}}/>
                </div>
              </div>
            )
          },
        ]}
      />
    </>
	);
}

function generateOverview(_data) {
  const data = {..._data}
  
  if (!data) return null
  const filter = ["organisatieID", "isActief"]

  return Object.keys(data).map(item => {
    if (filter.includes(item)) return null
    if (typeof data[item] === 'boolean') data[item] = data[item] ? "Ja" : "Nee"

    return (
      <div className={beheer_logic.recordContainer} key={item} style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'var(--primary-text-color)'}}>
        <p className={beheer_logic.recordText}>{item}</p>
        <p className={beheer_logic.recordText} style={{textTransform: 'unset'}}>{data[item]}</p>
      </div>
    )
  })
}

function checkInput(data) {
  if (!data.naam) return { success: false, message: 'Voer een locatie naam in.'}
  if (!data.contactpersoon) return { success: false, message: 'Voer een contactpersoon in.'}
  if (!data.email) return { success: false, message: 'Voer een emailadres in.'}
  if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(data.email)) return { success: false, message: 'Voer een geldig emailadres in. (voorbeeld@gmail.com)'}
  if (!data.telefoon) return { success: false, message: 'Voer een telefoonnummer in.'}
  if (!data.adres) return { success: false, message: 'Voer een adres in.'}
  if (!data.huisnummer) return { success: false, message: 'Voer een huisnummer in.'}
  if (!data.postcode) return { success: false, message: 'Voer een postcode in.'}
  if (!/^[0-9]{4}[A-Z]{2}$/.test(data.postcode)) return { success: false, message: 'Voer een geldige postcode in. (1234AB)'}
  if (!data.plaats) return { success: false, message: 'Voer een plaats in.'}
  return {success: true}
}