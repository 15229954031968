import { useContext, useEffect, useState } from "react";
import { Header, PagerContext, ResultList, Search, Button } from "../../../components";
import { DataStorageContext } from "../../../logic";
export default function Accounts() {
  const pager = useContext(PagerContext)
  const { readData, clearData } = useContext(DataStorageContext)
  const [searchValue, setSearchValue] = useState('')
  const [locatie, setLocatie] = useState({})
  const [accounts, setAccounts] = useState([])
  
  useEffect(() => {
    const fetchData = async () => {
      await pager.allowed_on_page()
      // clearData('beheer_accounts') // ? Why was this here?
      setAccounts(await readData('beheer_accounts'))
      const locaties = await readData('beheer_locaties')
      const locatieID = pager.getArgument('locatie')

      if (!locaties.success) return pager.navigateTo(`/beheer`)
      const locatie = locaties.values.find((locatie) => locatie.locatieID === parseInt(locatieID))
      if (!locatie) return pager.navigateTo(`/beheer`)
      setLocatie(locatie)
    };
    fetchData();
    // eslint-disable-next-line
  }, [pager.arguments]);

	return (
		<>
      <Header title={`Accounts - ${locatie.naam || ""}`} backUrl={`/beheer/locatie?locatie=${pager.getArgument('locatie')}`} playAnimation={false}/>

      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'end', width: '100%'}}>
        <div style={{width: '65%'}}><Search title="Zoeken" placeholder="Typ om te zoeken" value={searchValue} setValue={setSearchValue}/></div>
        <div style={{width: '30%'}}><Button title="Toevoegen" type="primary" styles={{height: '34px', width: '100%', fontSize: '1rem'}} callback={() => pager.navigateTo(`/beheer/locatie/accounts/nieuw?locatie=${pager.getArgument('locatie')}`)}/></div>
      </div>

      <ResultList 
        title={(item) => item.naam}
        data={accounts.values} 
        url={(account) => {return `/beheer/locatie/accounts/edit?account=${account.accountID}&locatie=${pager.getArgument('locatie')}`}} 
        filter={(accounts) => {
          const searchParts = searchValue.toLowerCase().split(' ').filter(part => part.trim() !== '');
          return searchParts.length === 0 ||
            searchParts.every(part =>
            accounts.email.toLowerCase().includes(part) ||
            accounts.gebruikersnaam.toLowerCase().includes(part) ||
            accounts.naam.toLowerCase().includes(part) ||
            String(accounts.rechtengroep).toLowerCase().includes(part) ||
            String(accounts.status).toLowerCase().includes(part) // TODO Add search like this: rechtengroep:1, status:2
            );
          }}
      />
		</>
	);
}
