import { input_logic, input_style } from "../../../styles";
import { RequiredIcon } from "../../../Images";
import React from "react";
/**
 * Input component.
 * @param {string} props.title - The title of the input component.
 * @param {string} props.placeholder - The placeholder text for the input component.
 * @param {string} props.value - The value of the input component.
 * @param {function} props.setValue - The function to set the value of the input component.
 * @param {function} props.callback - The function to set the value of the input component.
 * @param {string} props.name - The name of the input component.
 * @param {Object} props.styles - The styles to apply to the input component.
 * @param {number} props.charLimit - The character limit for the input value.
 * @param {boolean} props.hideLimit - Indicates if the character limit should be hidden.
 * @param {number|string} props.rows - The number of rows for a textarea input. 3
 * @param {number} props.tabIndex - The tab index of the input component.
 * @param {boolean} props.disabled - Indicates if the input is disabled.
 * @param {boolean} props.required - Indicates if the input is required.
 * @param {boolean} props.focus - Indicates if the input should be focused.
 * @returns {JSX.Element} The rendered input component.
 */
export default function Input({ title, rows, styles, tabIndex, charLimit, placeholder, value, setValue, callback, required, disabled, hideLimit, name, focus }) {
  rows = typeof rows === "number" ? rows : typeof rows === 'string' ? parseInt(rows) || undefined : undefined
  let element_styles = {};
  if (styles || typeof styles === "object") element_styles = styles

  const onValueChange = (...args) => {
    if (callback) return callback(...args)
    const value = args[0].target.value
    if (name) {
      if (value.length <= (charLimit || value.length + 1))
        setValue(prev => ({
          ...prev,
          [name]: value
        }))
    } else if (value.length <= (charLimit || value.length + 1)) setValue(value)
  }

  const countRender = () => {
    if (charLimit && !hideLimit) return <span className={[input_logic.text, value.length >= charLimit ? input_style.maxCounter : input_style.counter].join(" ")}> {value.length}/{charLimit}</span>
  }

  React.useEffect(() => {
    if (focus) inputField.current.focus()
    // eslint-disable-next-line
  }, [])

  const inputField = React.useRef(null)


  return (
    <div className={disabled ? input_logic.disabled : ''}>
      <header className={input_logic.headerWrapper}>
        <p className={[input_logic.text, input_style.text].join(" ")}>{title}</p>
        <div>
          {countRender()}
          <span> </span>
          {required && <RequiredIcon className={[input_style.required, input_logic.required].join(" ")} />}
        </div>
      </header>
      
      {
        rows === undefined
          ? <input    ref={inputField} disabled={disabled} style={element_styles} tabIndex={tabIndex} className={[input_logic.inputField, input_style.inputField].join(" ")} maxLength={charLimit} placeholder={placeholder} value={value} onChange={onValueChange}            ></input>
          : <textarea ref={inputField} disabled={disabled} style={element_styles} tabIndex={tabIndex} className={[input_logic.textField, input_style.textField].join(" ")}   maxLength={charLimit} placeholder={placeholder} value={value} onChange={onValueChange} rows={rows}></textarea>
      }
    </div>
  )
}
