import { useContext, useEffect, useState } from "react";
import { Header, ResultList, PagerContext } from "../../../components";
import { DataStorageContext } from "../../../logic";
import { ClockIcon } from "../../../Images";
export default function Logs() {
  const pager = useContext(PagerContext)
  const { readData } = useContext(DataStorageContext)
  const [logs, setLogs] = useState()
  const [searchValue, setSearchValue] = useState('')
  const [gebruikers, setGebruikers] = useState({})
	useEffect(() => {
		const fetchData = async () => {
			await pager.allowed_on_page()
      setLogs(await readData('logs'))
      setGebruikers(await readData('beheer_accounts'))
		}
		fetchData();
		// eslint-disable-next-line
	}, [pager.arguments]);

	return (
		<div>
      <Header 
        title="Logs"
        backUrl={`/beheer/locatie?locatie=${pager.getArgument('locatie')}`}
        playAnimation={false}
      />
      {logs && <ResultList showResults data={logs.values} limit={25} title={(item) => 
        <>
          <p style={{margin: '0'}}>{item.actie}</p>
          <div style={{display: "flex"}}>
            <p style={{margin: '0'}}>{gebruikers.values ? (gebruikers.values.find((gebruiker) => gebruiker.accountID === item.accountID)?.naam) : null}</p>
            <ClockIcon style={{width: '18px', fill: 'white', marginLeft: '10px'}}/>
            <p style={{margin: '0', marginLeft: '10px'}}>{formatDate(item.datum, 'long')}</p>
          </div>
        </>}
        filter={(log) => {
          const searchParts = searchValue.toLowerCase().split(' ').filter(part => part.trim() !== '');
          const gebruiker = gebruikers.values ? (gebruikers.values.find((gebruiker) => gebruiker.accountID === log.accountID)?.naam) : null
          return searchParts.length === 0 ||
            searchParts.every(part =>
              String(log.logID).toLowerCase().includes(part) ||
              String(log.accountID).toLowerCase().includes(part) ||
              (gebruiker && gebruiker.toLowerCase().includes(part)) ||
              log.actie.toLowerCase().includes(part) ||
              formatDate(log.datum, 'long').toLowerCase().includes(part)
            );
          }}
      />}
    </div>
	);
}

function formatDate(dateString, type = 'short') {
  const date = new Date(dateString);
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear().toString();
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  const seconds = ("0" + date.getSeconds()).slice(-2);

  switch(type) {
    case 'long':
      return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    case 'short':
    default:
      return `${day}-${month}-${year}`;
  }
};