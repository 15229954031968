import { Button, Password, Input, NotificationContext} from "../components";
import { login_logic, login_style } from '../styles';
import React, { useState, useEffect, useContext } from 'react';
import { PagerContext } from "../components";
import { postRequest } from "../logic";
import { KwaliteitLogo } from '../Images/index'

export default function Reset() {
  const pager = useContext(PagerContext);
  const notificationController = useContext(NotificationContext);
  const [password, setPassword] = useState("");
  const [passwordRetype, setPasswordRetype] = useState("");
  const [token, setToken] = useState("");

  const resetPassword = async () => {
    let data;
    if (password !== passwordRetype) return notificationController.sendMessage({type: 'warning', message: "Wachtwoorden komen niet overeen"});
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(password)) return notificationController.sendMessage({type: 'warning', message: 'Wachtwoord moet minimaal 8 karakters lang zijn, minimaal 1 hoofdletter, 1 kleine letter, 1 cijfer en 1 speciaal karakter bevatten.'})
    await postRequest('/api/auth/reset', {
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({token: token, wachtwoord: password})
    }).then((response) => {data = response})
    if (!data.success) return notificationController.sendMessage({type: 'error', message: 'Er is iets mis gegaan tijdens het wijzigen van uw wachtwoord. Neem contact op met uw beheerder.'})
    pager.signout()
  }

  useEffect(() => {
    const token = pager.getArgument('token')
    if (!token || token === '') return pager.signout()
    setToken(token)
  // eslint-disable-next-line
  }, [])

  return (
    <div className={[login_logic.panel, login_style.panel].join(' ')}>
      <div className={login_logic.logoContainer}>
        <img src={KwaliteitLogo} className={login_logic.logo} alt=""></img>
      </div>
      <form className={login_logic.inputForm}>
          <h2 className={[login_logic.title, login_style.title].join(' ')}>Wachtwoord Wijzigen</h2>
          <Password title="Wachtwoord" placeholder="Wachtwoord" value={password} setValue={setPassword} tabIndex={2}/>
          <Password title="Herhaal uw wachtwoord" placeholder="Herhaal uw wachtwoord" value={passwordRetype} setValue={setPasswordRetype} tabIndex={3}/>
          <Button title='Wijzigen' type="primary" callback={resetPassword} styles={{width: '80%', display: 'block', margin: 'auto'}} tabIndex={4}></Button>
        </form>
    </div>
  );
}
