import { useContext, useEffect, useState, useRef } from "react";
import { Button, Input, Header, NotificationContext, PagerContext, Steps } from "../../../components";
import { DataStorageContext, putRequest, fetchMedia, postRequest } from "../../../logic";
import { beleven_logic } from "../../../styles";
export default function Belofte() {
  const imageInputRef = useRef()
  const pager = useContext(PagerContext)
  const notificationController = useContext(NotificationContext)
  const { readData, clearData } = useContext(DataStorageContext)
  const [requestSend, setRequestSend] = useState(false)
  const [uploading, setUploading] = useState(false)

  const [data, setData] = useState({})

	useEffect(() => {
		const fetchData = async () => {
			await pager.allowed_on_page()
      createDataObject()
    }
		fetchData();
		// eslint-disable-next-line
	}, [pager.arguments]);

  const createDataObject = async () => {
    const _values = Object.values((await readData('beloftes')).values).filter((item) => String(item.locatieID) === pager.getArgument('locatie'));
    if (Object.keys(_values).length === 0) return pager.navigateTo(`/beheer/locatie?locatie=${pager.getArgument('locatie')}`);

    for (const item of _values) {
      if (item.afbeelding.startsWith('blob:')) continue;
      const response = await fetchMedia(`/api/data/media?${new URLSearchParams({id: item.afbeelding})}`, {});
      if (response.status === 500) {item.afbeelding_blob = null; continue}

      if (typeof response.blob === 'function') {

        const blob = await response.blob();
        item.afbeelding_blob = URL.createObjectURL(blob);
      } else item.afbeelding_blob = null;
    }

    setData(Object.keys(_values).reduce((acc, key) => {
      acc[key] = _values[key];
      return acc;
    }, {}));
  }

  const onImageChange = async (e, belofteIndex) => {
    setUploading(true);
    const file = e.target.files[0];

    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
      return notificationController.sendMessage({type: 'error', message: `Ongeldig bestandstype. Alleen PNG & JPEG bestanden zijn toegestaan.`});
    }
    
    let result;
    const formData = new FormData();
    formData.append("type", "belofte");

    const imageFile = await fetch(URL.createObjectURL(file))
    .then((res) => res.blob())
    .then((blob) => new File([blob], "image.jpg", { type: "image/jpeg" }));
    formData.append("media", imageFile);


    await postRequest('/api/data/media', {
      body: formData
    }).then((response) => {result = response})
    if (!result.success) {setUploading(false); return notificationController.sendMessage({type: 'error', message: `Er is iets mis gegaan tijdens het uploaden van de afbeelding.`});}; // Error!
    
    setData(prev => ({
      ...prev,
      [belofteIndex]: {
        ...prev[belofteIndex],
        afbeelding: result.filename,
        afbeelding_blob: URL.createObjectURL(imageFile)
      }
    }))
    setUploading(false);
  }

  const saveBeloftes = async (index, handleSwipe) => {
    console.log(data[index])

    setRequestSend(true);
    let result;
    const checkResult = checkInput(data[index])
    if (!checkResult.success) {setRequestSend(false); return notificationController.sendMessage({type: 'error', message: checkResult.message})}
    await putRequest('/api/data/beloftes/', {
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...data[index]
      })
    }).then((response) => {result = response})
    if (!result.success) {setRequestSend(false); return notificationController.sendMessage({type: 'error', message: 'Er is iets fout gegaan bij het opslaan van de beloftes. Probeer het opnieuw.'})}
    clearData('beloftes')
    readData('beloftes')
    createDataObject()
    setRequestSend(false);
    notificationController.sendMessage({type: 'success', message: 'De beloftes zijn bewerkt en opgeslagen.'})
    handleSwipe('left')
  }
  
  const onChange = (event, number, name, key, subNumber) => {
    // * This code has been generated by AI, i have no idea how it works, but it does. :)
    const value = event.target.value;

    if (key !== 'subbeloftes') {
      setData(prev => ({
        ...prev,
        [number]: {
          ...prev[number],
          [name]: value
        }
      }));
    } else {
      setData(prev => {
        const updatedSubbeloftes = Array.isArray(prev[number][key])
          ? prev[number][key].map((subBelofte, index) => 
              index === subNumber ? { ...subBelofte, subBelofte: value } : subBelofte
            )
          : [];
    
        return {
          ...prev,
          [number]: {
            ...prev[number],
            [key]: updatedSubbeloftes
          }
        };
      });
    }
  };

	return (
    <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%'}}>
      <div>
        <Header 
          title={`Beloftes` /* Locatie Naam toevoegen? */} 
          backUrl={`/beheer/locatie?locatie=${pager.getArgument('locatie')}`}
          playAnimation={false}
        />
      </div>
      <Steps steps={
        Object.keys(data).map((item) => ({
            title: `Belofte #${data[item].nummer}`,
            content: (handleSwipe) => (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent:'start', height: '100%'}}>
              <Input required title="Belofte" value={data?.[item]?.belofte ?? ''} callback={(e) => onChange(e, item, 'belofte')} placeholder="Vul de hoofd-belofte in..."/>
                <div>
                  <Input required title="Sub-belofte 1" value={data?.[item]?.subbeloftes?.[0]?.subBelofte ?? ''} callback={(e) => onChange(e, item, 'subbeloftes', 'subbeloftes', 0)} placeholder="Vul de sub-belofte in..."/>
                  <Input required title="Sub-belofte 2" value={data?.[item]?.subbeloftes?.[1]?.subBelofte ?? ''} callback={(e) => onChange(e, item, 'subbeloftes', 'subbeloftes', 1)} placeholder="Vul de sub-belofte in..."/>
                  <Input required title="Sub-belofte 3" value={data?.[item]?.subbeloftes?.[2]?.subBelofte ?? ''} callback={(e) => onChange(e, item, 'subbeloftes', 'subbeloftes', 2)} placeholder="Vul de sub-belofte in..."/>
                </div>
  
                <div className={beleven_logic.imgWrapper} style={{display: 'flex', alignItems: "center", justifyContent: 'center'}}>
                  {data?.[item]?.afbeelding_blob?.startsWith('blob') && <img src={data?.[item]?.afbeelding_blob}></img>}
                </div>
  
                <input 
                  ref={imageInputRef} 
                  type="file" 
                  onChange={(e) => onImageChange(e, item)} 
                  style={{display: 'none'}} 
                  accept="image/png, image/jpg, image/jpeg, video/mp4" 
                  capture="environment"
                />
                <div style={{width: '100%'}}>
                  <Button styles={{fontSize: '.9rem', marginTop: '10px', width: '100%'}} type="primary" title="Media bestand selecteren" callback={() => imageInputRef.current.click()}/>
                  <Button disabled={requestSend || uploading} styles={{fontSize: '.9rem', marginTop: '10px', width: '100%'}} type="primary" title={uploading ? 'Wachten tot afbeelding geupload is...': 'Opslaan'} callback={() => saveBeloftes(item, handleSwipe)}/>
                </div>
              </div>
            )
          })
        )
      }/>
    </div>
	);
}

function checkInput(data) {
  if (!data.belofte) return { success: false, message: 'Voer een hoofd-belofte in.'}
  if (!data.subbeloftes[0].subBelofte) return { success: false, message: 'Voer sub-belofte 1 in.'}
  if (!data.subbeloftes[1].subBelofte) return { success: false, message: 'Voer sub-belofte 2 in.'}
  if (!data.subbeloftes[2].subBelofte) return { success: false, message: 'Voer sub-belofte 3 in.'}
  return {success: true}
}