import { input_logic, input_style } from "../../../styles";
import { RequiredIcon } from "../../../Images";
import React from "react";
/**
 * Renders a date input field component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the input field.
 * @param {string} props.type - The type of the input field (date, datetime-local, or time).
 * @param {Object} props.styles - The custom styles to apply to the input field.
 * @param {number} props.tabIndex - The tab index of the input field.
 * @param {string} props.placeholder - The placeholder text of the input field.
 * @param {string} props.value - The value of the input field.
 * @param {Function} props.setValue - The function to set the value of the input field.
 * @param {boolean} props.required - Indicates if the input field is required.
 * @param {boolean} props.disabled - Indicates if the input field is disabled.
 * @param {string} props.name - The name of the input field.
 * @param {boolean} props.focus - Indicates if the input field should be focused.
 * @returns {JSX.Element} The rendered date input field component.
 * @throws {Error} If the type is not one of the allowed types: "date", "datetime-local", "time".
 */
export default function DateInput({ title, type, styles, tabIndex, placeholder, value, setValue, required, disabled, name, focus }) {
  if (type !== "date" && type !== "datetime-local" && type !== "time") throw new Error('type is either null or not one of these types: "date", "datetime-local", "time". /src/components/assets/elements/date.js')

  let element_styles = {};
  if (styles || typeof styles === "object") element_styles = styles

  const onValueChange = (e) => {
    const value = e.target.value
    if (name) {
      setValue(prev => ({
        ...prev,
        [name]: value
      }))
    } else setValue(value)
  }

  React.useEffect(() => {
    if (focus) inputField.current.focus()
    // eslint-disable-next-line
  }, [])

  const inputField = React.useRef(null)

  return (
    <div className={disabled ? input_logic.disabled : ''}>
      <label className={[input_logic.text, input_style.text].join(" ")}>
        <span>{title}</span> 
        {required && <RequiredIcon className={[input_style.required, input_logic.required].join(" ")}/>}
      </label>
      <input 
        ref={inputField}
        disabled={disabled}
        tabIndex={tabIndex} 
        style={element_styles} 
        type={type} 
        className={[input_logic.dateField, input_style.inputField].join(" ")} 
        placeholder={placeholder} 
        value={value} 
        onChange={onValueChange}
      ></input>
    </div>
  )
}
