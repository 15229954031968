import { button_logic, button_style } from "../../../styles";

/**
 * Button component.
 * @param {string} props.title - The title text for the button.
 * @param { string | React.FunctionComponent } props.icon - The icon for the button.
 * @param {Object} props.styles - The styles object for the button.
 * @param {function} props.callback - The callback function to be called on button click.
 * @param {boolean} props.disabled - Whether the button is disabled or not.
 * @param {number} props.tabIndex - The tab index for the button.
 * @returns {JSX.Element} The rendered button component.
 */
export default function MenuButton({ title, icon, styles, callback, disabled, tabIndex }) {
  let element_styles = {};
  if (styles || typeof styles === "object") element_styles = styles;

  const Icon = icon;

  return (
    <div 
      tabIndex={tabIndex} 
      disabled={disabled}
      onClick={!disabled ? callback : undefined}
      style={disabled ? {opacity: '0.6', cursor: 'not-allowed', ...element_styles} : element_styles}
      className={[
        button_logic.iconButton, 
        button_style.iconButton, 
        disabled ? button_logic.disabled : ''
      ].join(' ')}>
      {icon ? typeof icon === 'object' ? <Icon/> : <img src={icon} alt=""></img> : null}
      <div>{title}</div>
    </div>
  )
}
