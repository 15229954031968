import { button_logic, button_style } from "../../../styles";

/**
 * Button component.
 * @param {string} props.title - The title text for the button.
 * @param {Object} props.styles - The styles object for the button.
 * @param {function} props.callback - The callback function to be called on button click.
 * @param {boolean} props.disabled - Whether the button is disabled or not.
 * @param {number} props.tabIndex - The tab index for the button.
 * @returns {JSX.Element} The rendered button component.
 */
export default function ListButton({ title, styles, callback, disabled, tabIndex }) {
  let element_styles = {};
  if (styles || typeof styles === "object") element_styles = styles;

  return (
    <>
      <div
        disabled={disabled}
        tabIndex={tabIndex}
        onClick={!disabled ? callback : undefined}
        style={element_styles}
        className={[
          button_logic.listButton,
          button_style.listButton,
          disabled ? button_logic.disabled : ''
        ].join(" ")}
      >
        <p style={{ color: "white", width: '80%' }}>{title}</p>
        <p style={{ alignSelf: "center", width: '10%' }}>{"≫"}</p>
      </div>
    </>
  );
}
